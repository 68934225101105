import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import i18n from 'i18next';

import Alert, { AlertTitle } from '../../../core/components/Alert';
import Page from '../../../core/components/Page';
import { UPDATE_PROFILE, CHANGE_PASSWORD } from '../../queries';
import Input from '../../../core/components/Input';
import ProgressButton from '../../../core/containers/ProgressButton';
import Select from '../../../core/containers/Select';
import MenuItem from '../../../core/components/MenuItem';

import './style.scss';
import Layout from '../../../core_updated/components/Layout';

const GeneralSection = (props) => {
    const { user } = props;
    const { t } = useTranslation('users');

    const [updateProfile, { loading: updateProfileLoading, error: updateProfileError }] = useMutation(UPDATE_PROFILE);

    const [showSuccess, setShowSuccess] = useState(false);
    const [firstName, setFirstName] = useState(user?.firstName || '');
    const [lastName, setLastName] = useState(user?.lastName || '');

    const handleSubmit = () => {
        setShowSuccess(false);
        return updateProfile({
            variables: {
                id: user.id.toString(),
                firstName,
                lastName,
            },
        })
            .then(() => {
                setShowSuccess(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <section className="user-profile__section user-profile__section--general">
            <h3 className="user-profile__section-title">{t('profile.generalTitle')}</h3>

            {updateProfileError && (
                <Alert severity="error" className="alert--no-margin">
                    <AlertTitle>{updateProfileError?.message}</AlertTitle>
                </Alert>
            )}

            {showSuccess && (
                <Alert severity="success" className="alert--no-margin">
                    <AlertTitle>{t('profile.successMessage')}</AlertTitle>
                </Alert>
            )}

            <div className="user-profile__group">
                <label className="user-profile__label">{t('form.firstName')}</label>
                <Input
                    className="user-profile__input"
                    placeholder="Robyn"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </div>

            <div className="user-profile__group">
                <label className="user-profile__label">{t('form.lastName')}</label>
                <Input
                    className="user-profile__input"
                    placeholder="Roboter"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </div>

            <div className="user-profile__group">
                <label className="user-profile__label">{t('form.email')}</label>
                <Input disabled={true} className="user-profile__input" value={user.email} />
            </div>

            <div className="user-profile__group">
                <ProgressButton label={t('profile.submitLabel')} onClick={handleSubmit} />
            </div>
        </section>
    );
};

const NotificationSection = (props) => {
    const { user } = props;
    const { t } = useTranslation('users');

    const [updateProfile, { loading: updateProfileLoading, error: updateProfileError }] = useMutation(UPDATE_PROFILE);

    const [showSuccess, setShowSuccess] = useState(false);
    const [sendEmailNotification, setSendEmailNotification] = useState(user ? user.sendEmailNotification : true);

    const handleSubmit = () => {
        setShowSuccess(false);
        return updateProfile({
            variables: {
                id: user.id.toString(),
                sendEmailNotification: sendEmailNotification,
            },
        })
            .then(() => {
                setShowSuccess(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <section className="user-profile__section user-profile__section--general">
            <h3 className="user-profile__section-title">{t('profile.notificationTitle')}</h3>

            {updateProfileError && (
                <Alert severity="error" className="alert--no-margin">
                    <AlertTitle>{updateProfileError?.message}</AlertTitle>
                </Alert>
            )}

            {showSuccess && (
                <Alert severity="success" className="alert--no-margin">
                    <AlertTitle>{t('profile.successMessage')}</AlertTitle>
                </Alert>
            )}

            <div className="user-profile__group">
                <label className="user-profile__label">{t('form.sendEmailNotification')}</label>

                <Select
                    value={sendEmailNotification}
                    onChange={(value) => setSendEmailNotification(value)}
                    className="user-profile__input"
                >
                    <MenuItem value={true} label={t('form.sendEmailNotificationTrue')} />
                    <MenuItem value={false} label={t('form.sendEmailNotificationFalse')} />
                </Select>
            </div>

            <div className="user-profile__group">
                <ProgressButton label={t('profile.submitLabel')} onClick={handleSubmit} />
            </div>
        </section>
    );
};

const PasswordSection = (props) => {
    const { user } = props;
    const { t } = useTranslation('users');

    const [changePassword, { loading: changePasswordLoading, error: changePasswordError }] =
        useMutation(CHANGE_PASSWORD);

    const [showSuccess, setShowSuccess] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

    const handleSubmit = () => {
        setShowSuccess(false);
        return changePassword({
            variables: {
                oldPassword,
                newPassword1: newPassword,
                newPassword2: newPasswordConfirm,
            },
        })
            .then(() => {
                setShowSuccess(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <section className="user-profile__section user-profile__section--general">
            <h3 className="user-profile__section-title">{t('profile.passwordTitle')}</h3>

            {changePasswordError && (
                <Alert severity="error" className="alert--no-margin">
                    <AlertTitle>{changePasswordError?.message}</AlertTitle>
                </Alert>
            )}

            {showSuccess && (
                <Alert severity="success" className="alert--no-margin">
                    <AlertTitle>{t('profile.successMessage')}</AlertTitle>
                </Alert>
            )}

            <div className="user-profile__group">
                <label className="user-profile__label">{t('form.oldPassword')}</label>
                <Input
                    placeholder="••••••••••••"
                    className="user-profile__input"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    type="password"
                />
            </div>

            <div className="user-profile__group">
                <label className="user-profile__label">{t('form.newPassword')}</label>
                <Input
                    placeholder="••••••••••••"
                    className="user-profile__input"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    type="password"
                />
            </div>

            <div className="user-profile__group">
                <label className="user-profile__label">{t('form.newPasswordConfirm')}</label>
                <Input
                    placeholder="••••••••••••"
                    className="user-profile__input"
                    value={newPasswordConfirm}
                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                    type="password"
                />
            </div>

            <div className="user-profile__group">
                <ProgressButton label={t('profile.submitLabel')} onClick={handleSubmit} />
            </div>
        </section>
    );
};

const LanguageSection = (props) => {
    const { user } = props;
    const { t } = useTranslation('users');

    const [updateProfile, { loading: updateProfileLoading, error: updateProfileError }] = useMutation(UPDATE_PROFILE);

    const [language, setLanguage] = useState(i18n.language.substr(0, 2));

    const handleSubmit = () => {
        return updateProfile({
            variables: {
                id: user.id.toString(),
                language,
            },
        })
            .then(() => {
                return i18n.changeLanguage(language);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <section className="user-profile__section user-profile__section--general">
            <h3 className="user-profile__section-title">{t('profile.languageTitle')}</h3>

            <div className="user-profile__group">
                <label className="user-profile__label">{t('form.language')}</label>
                <Select className="user-profile__input" value={language} onChange={(value) => setLanguage(value)}>
                    <MenuItem value="de" label="Deutsch" />
                    <MenuItem value="en" label="English" />
                    <MenuItem value="pl" label="Polski" />
                </Select>
            </div>

            <div className="user-profile__group">
                <ProgressButton label={t('profile.submitLabel')} onClick={handleSubmit} />
            </div>
        </section>
    );
};

const Profile = (props) => {
    const { t } = useTranslation('users');
    const { user } = props;

    return (
        <Layout>
            <Page className="user-profile page--slim">
                <div className="user-profile__head">
                    <div className="user-profile__title">{t('profile.title')}</div>
                </div>

                <div className="user-profile__sections">
                    <GeneralSection user={user} />
                    <NotificationSection user={user} />
                    {user.passwordEnabled && <PasswordSection user={user} />}
                    <LanguageSection user={user} />
                </div>
            </Page>
        </Layout>
    );
};

export default Profile;
