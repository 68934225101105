import Alert, { AlertTitle } from '../../../core/components/Alert';
import DropArea from '../../../core/containers/DropArea';
import ProgressButton from '../../../core/containers/ProgressButton';
import { default as React, useEffect, useState } from 'react';
import { MasterdataConfig } from '../../types';
import { appInsights } from '../../../core/analytics/applicationInsights';
import { uploadMasterdata } from '../../../core/analytics/customEvents';
import {
    uploadArticleList,
    uploadClientList,
    uploadContactList,
    uploadConversionFactorList,
    uploadDebitorArticleList,
    uploadDeliveryAddressList,
    uploadInvoiceAddressList,
    uploadReceiverList,
    uploadOrderMatchingList,
} from '../../queries';
import { useTranslation } from 'react-i18next';
import {
    ADDRESS_LIST_COLUMNS,
    ARTICLE_LIST_COLUMNS,
    CLIENT_LIST_COLUMNS,
    CONTACT_COLUMNS,
    CONVERSION_FACTOR_COLUMNS,
    DEBITOR_ARTICLE_LIST_COLUMNS,
    ORDER_MATCHING_COLUMNS,
} from './fileSchemas';

import './style.scss';

interface IProps {
    config: MasterdataConfig;
    channelId: string;
    user: any;
}

// Extracted from MasterDataConfiguration
export const MasterdataFileUpload = ({ user, channelId, config }: IProps) => {
    const { t } = useTranslation('config');

    const [articleUploadError, setArticleUploadError] = useState('');
    const [debitorArticleUploadError, setDebitorArticleUploadError] = useState('');
    const [clientUploadError, setClientUploadError] = useState('');
    const [receiverUploadError, setReceiverUploadError] = useState('');
    const [deliveryAddressUploadError, setDeliveryAddressUploadError] = useState('');
    const [invoiceAddressUploadError, setInvoiceAddressUploadError] = useState('');
    const [conversionFactorUploadError, setConversionFactorUploadError] = useState('');
    const [contactUploadError, setContactUploadError] = useState('');
    const [orderMatchingUploadError, setOrderMatchingUploadError] = useState('');

    const [articleFiles, setArticleFiles] = useState([]);
    const [debitorArticleFiles, setDebitorArticleFiles] = useState([]);
    const [clientFiles, setClientFiles] = useState([]);
    const [receiverFiles, setReceiverFiles] = useState([]);
    const [deliveryAddressFiles, setDeliveryAddressFiles] = useState([]);
    const [invoiceAddressFiles, setInvoiceAddressFiles] = useState([]);
    const [conversionFactorFiles, setConversionFactorFiles] = useState([]);
    const [contactFiles, setContactFiles] = useState([]);
    const [orderMatchingFiles, setOrderMatchingFiles] = useState([]);

    useEffect(() => {
        if (debitorArticleUploadError) {
            setDebitorArticleUploadError('');
        }
    }, [debitorArticleFiles]);

    useEffect(() => {
        if (articleUploadError) {
            setArticleUploadError('');
        }
    }, [articleFiles]);

    useEffect(() => {
        if (clientUploadError) {
            setClientUploadError('');
        }
    }, [clientFiles]);

    const handleArticleListUpload = () => {
        appInsights?.trackEvent(...uploadMasterdata(user, channelId, null, { masterdataName: 'article' }));
        return uploadArticleList(articleFiles, config)
            .then(() => {
                return setArticleFiles([]);
            })
            .catch((err) => {
                setArticleUploadError(err.response?.data || err.message);
                console.error(err);
            });
    };

    const handleDebitorArticleListUpload = () => {
        appInsights?.trackEvent(...uploadMasterdata(user, channelId, null, { masterdataName: 'debitorArticle' }));
        return uploadDebitorArticleList(debitorArticleFiles, config)
            .then(() => {
                return setDebitorArticleFiles([]);
            })
            .catch((err) => {
                setDebitorArticleUploadError(err.response?.data || err.message);
                console.error(err);
            });
    };

    const handleClientListUpload = () => {
        appInsights?.trackEvent(...uploadMasterdata(user, channelId, null, { masterdataName: 'client' }));
        return uploadClientList(clientFiles, config)
            .then(() => {
                return setClientFiles([]);
            })
            .catch((err) => {
                setClientUploadError(err.response?.data || err.message);
                console.error(err);
            });
    };

    const handleReceiverListUpload = () => {
        appInsights?.trackEvent(...uploadMasterdata(user, channelId, null, { masterdataName: 'receiver' }));
        return uploadReceiverList(receiverFiles, config)
            .then(() => {
                return setReceiverFiles([]);
            })
            .catch((err) => {
                setReceiverUploadError(err.response?.data || err.message);
                console.error(err);
            });
    };

    const handleDeliveryAddressListUpload = () => {
        appInsights?.trackEvent(...uploadMasterdata(user, channelId, null, { masterdataName: 'deliveryAddress' }));
        return uploadDeliveryAddressList(deliveryAddressFiles, config)
            .then(() => {
                return setDeliveryAddressFiles([]);
            })
            .catch((err) => {
                setDeliveryAddressUploadError(err.response?.data || err.message);
                console.error(err);
            });
    };

    const handleInvoiceAddressListUpload = () => {
        appInsights?.trackEvent(...uploadMasterdata(user, channelId, null, { masterdataName: 'invoiceAddress' }));
        return uploadInvoiceAddressList(invoiceAddressFiles, config)
            .then(() => {
                return setInvoiceAddressFiles([]);
            })
            .catch((err) => {
                setInvoiceAddressUploadError(err.response?.data || err.message);
                console.error(err);
            });
    };

    const handleConversionFactorListUpload = () => {
        appInsights?.trackEvent(...uploadMasterdata(user, channelId, null, { masterdataName: 'conversionFactor' }));
        return uploadConversionFactorList(conversionFactorFiles, config)
            .then(() => {
                return setConversionFactorFiles([]);
            })
            .catch((err) => {
                setConversionFactorUploadError(err.response?.data || err.message);
                console.error(err);
            });
    };
    const handleContactListUpload = () => {
        appInsights?.trackEvent(...uploadMasterdata(user, channelId, null, { masterdataName: 'contact' }));
        setContactUploadError(undefined);
        return uploadContactList(contactFiles, config)
            .then(() => {
                return setContactFiles([]);
            })
            .catch((err) => {
                setContactUploadError(err.response?.data || err.message);
                console.error(err);
            });
    };

    const handleOrderMatchingListUpload = () => {
        appInsights?.trackEvent(...uploadMasterdata(user, channelId, null, { masterdataName: 'order_matching' }));
        setOrderMatchingUploadError(undefined);
        return uploadOrderMatchingList(orderMatchingFiles, config)
            .then(() => {
                return setOrderMatchingFiles([]);
            })
            .catch((err) => {
                setOrderMatchingUploadError(err.response?.data || err.message);
                console.error(err);
            });
    };

    return (
        <>
            {config?.articleLookupDefinitionId && (
                <div className="form file-form">
                    {articleUploadError && (
                        <Alert severity="error" className="alert--no-margin">
                            <AlertTitle>{articleUploadError}</AlertTitle>
                        </Alert>
                    )}

                    <DropArea
                        placeholder={t('masterDataConfig.uploadArticleListPlaceholder')}
                        accept={{ 'text/csv': ['.csv'] }}
                        onFilesChange={setArticleFiles}
                        files={articleFiles}
                        maxFiles={1}
                    />

                    <div className="form__note">
                        {t('masterDataConfig.csvNote')} <br />
                        {ARTICLE_LIST_COLUMNS.join(', ')}
                        <br />
                        <a target="_blank" href="/static/samples/csv/article_list.csv">
                            {t('masterDataConfig.downloadSampleFile')}
                        </a>
                    </div>

                    <div className="form__group">
                        <ProgressButton
                            label={t('masterDataConfig.uploadArticleListSubmit')}
                            onClick={handleArticleListUpload}
                            disabled={!articleFiles.length}
                        />
                    </div>
                </div>
            )}

            {config?.debitorArticleLookupDefinitionId && (
                <div className="form file-form">
                    {debitorArticleUploadError && (
                        <Alert severity="error" className="alert--no-margin">
                            <AlertTitle>{debitorArticleUploadError}</AlertTitle>
                        </Alert>
                    )}

                    <DropArea
                        placeholder={t('masterDataConfig.uploadDebitorArticleListPlaceholder')}
                        accept={{ 'text/csv': ['.csv'] }}
                        onFilesChange={setDebitorArticleFiles}
                        files={debitorArticleFiles}
                        maxFiles={1}
                    />

                    <div className="form__note">
                        {t('masterDataConfig.csvNote')} <br />
                        {DEBITOR_ARTICLE_LIST_COLUMNS.join(', ')}
                        <br />
                        <a target="_blank" href="/static/samples/csv/partitioned_article_list.csv">
                            {t('masterDataConfig.downloadSampleFile')}
                        </a>
                    </div>

                    <div className="form__group">
                        <ProgressButton
                            label={t('masterDataConfig.uploadDebitorArticleListSubmit')}
                            onClick={handleDebitorArticleListUpload}
                            disabled={!debitorArticleFiles.length}
                        />
                    </div>
                </div>
            )}

            {config?.clientLookupDefinitionId && (
                <div className="form file-form">
                    {clientUploadError && (
                        <Alert severity="error" className="alert--no-margin">
                            <AlertTitle>{clientUploadError}</AlertTitle>
                        </Alert>
                    )}

                    <DropArea
                        placeholder={t('masterDataConfig.uploadClientListPlaceholder')}
                        accept={{ 'text/csv': ['.csv'] }}
                        onFilesChange={setClientFiles}
                        files={clientFiles}
                        maxFiles={1}
                    />

                    <div className="form__note">
                        {t('masterDataConfig.csvNote')} <br />
                        {CLIENT_LIST_COLUMNS.join(', ')}
                        <br />
                        <a target="_blank" href="/static/samples/csv/client_list.csv">
                            {t('masterDataConfig.downloadSampleFile')}
                        </a>
                    </div>

                    <div className="form__group">
                        <ProgressButton
                            label={t('masterDataConfig.uploadClientListSubmit')}
                            onClick={handleClientListUpload}
                            disabled={!clientFiles.length}
                        />
                    </div>
                </div>
            )}

            {config?.receiverLookupDefinitionId && (
                <div className="form file-form">
                    {receiverUploadError && (
                        <Alert severity="error" className="alert--no-margin">
                            <AlertTitle>{receiverUploadError}</AlertTitle>
                        </Alert>
                    )}

                    <DropArea
                        placeholder={t('masterDataConfig.uploadReceiverListPlaceholder')}
                        accept={{ 'text/csv': ['.csv'] }}
                        onFilesChange={setReceiverFiles}
                        files={receiverFiles}
                        maxFiles={1}
                    />

                    <div className="form__note">
                        {t('masterDataConfig.csvNote')} <br />
                        {CLIENT_LIST_COLUMNS.join(', ')}
                        <br />
                        <a target="_blank" href="/static/samples/csv/client_list.csv">
                            {t('masterDataConfig.downloadSampleFile')}
                        </a>
                    </div>

                    <div className="form__group">
                        <ProgressButton
                            label={t('masterDataConfig.uploadReceiverListSubmit')}
                            onClick={handleReceiverListUpload}
                            disabled={!receiverFiles.length}
                        />
                    </div>
                </div>
            )}

            {config?.deliveryAddressLookupDefinitionId && (
                <div className="form file-form">
                    {deliveryAddressUploadError && (
                        <Alert severity="error" className="alert--no-margin">
                            <AlertTitle>{deliveryAddressUploadError}</AlertTitle>
                        </Alert>
                    )}

                    <DropArea
                        placeholder={t('masterDataConfig.uploadDeliveryAddressListPlaceholder')}
                        accept={{ 'text/csv': ['.csv'] }}
                        onFilesChange={setDeliveryAddressFiles}
                        files={deliveryAddressFiles}
                        maxFiles={1}
                    />

                    <div className="form__note">
                        {t('masterDataConfig.csvNote')} <br />
                        {ADDRESS_LIST_COLUMNS.join(', ')}
                        <br />
                        <a target="_blank" href="/static/samples/csv/address_list.csv">
                            {t('masterDataConfig.downloadSampleFile')}
                        </a>
                    </div>

                    <div className="form__group">
                        <ProgressButton
                            label={t('masterDataConfig.uploadDeliveryAddressListSubmit')}
                            onClick={handleDeliveryAddressListUpload}
                            disabled={!deliveryAddressFiles.length}
                        />
                    </div>
                </div>
            )}

            {config?.invoiceAddressLookupDefinitionId && (
                <div className="form file-form">
                    {invoiceAddressUploadError && (
                        <Alert severity="error" className="alert--no-margin">
                            <AlertTitle>{invoiceAddressUploadError}</AlertTitle>
                        </Alert>
                    )}
                    <div>
                        <DropArea
                            placeholder={t('masterDataConfig.uploadInvoiceAddressListPlaceholder')}
                            accept={{ 'text/csv': ['.csv'] }}
                            onFilesChange={setInvoiceAddressFiles}
                            files={invoiceAddressFiles}
                            maxFiles={1}
                        />

                        <div className="form__note">
                            {t('masterDataConfig.csvNote')} <br />
                            {ADDRESS_LIST_COLUMNS.join(', ')}
                            <br />
                            <a target="_blank" href="/static/samples/csv/address_list.csv">
                                {t('masterDataConfig.downloadSampleFile')}
                            </a>
                        </div>

                        <div className="form__group">
                            <ProgressButton
                                label={t('masterDataConfig.uploadInvoiceAddressListSubmit')}
                                onClick={handleInvoiceAddressListUpload}
                                disabled={!invoiceAddressFiles.length}
                            />
                        </div>
                    </div>
                </div>
            )}

            {config?.conversionFactorLookupDefinitionId && (
                <div className="form file-form">
                    {conversionFactorUploadError && (
                        <Alert severity="error" className="alert--no-margin">
                            <AlertTitle>{conversionFactorUploadError}</AlertTitle>
                        </Alert>
                    )}

                    <DropArea
                        placeholder={t('masterDataConfig.uploadConversionFactorListPlaceholder')}
                        accept={{ 'text/csv': ['.csv'] }}
                        onFilesChange={setConversionFactorFiles}
                        files={conversionFactorFiles}
                        maxFiles={1}
                    />

                    <div className="form__note">
                        {t('masterDataConfig.csvNote')} <br />
                        {CONVERSION_FACTOR_COLUMNS.join(', ')}
                        <br />
                        <a target="_blank" href="/static/samples/csv/conversion_factor_list.csv">
                            {t('masterDataConfig.downloadSampleFile')}
                        </a>
                    </div>

                    <div className="form__group">
                        <ProgressButton
                            label={t('masterDataConfig.uploadConversionFactorListSubmit')}
                            onClick={handleConversionFactorListUpload}
                            disabled={!conversionFactorFiles.length}
                        />
                    </div>
                </div>
            )}

            {config?.contactLookupDefinitionId && (
                <div className="form file-form">
                    {contactUploadError && (
                        <Alert severity="error" className="alert--no-margin">
                            <AlertTitle>{contactUploadError}</AlertTitle>
                        </Alert>
                    )}

                    <DropArea
                        placeholder={t('masterDataConfig.uploadContactListPlaceholder')}
                        accept={{ 'text/csv': ['.csv'] }}
                        onFilesChange={setContactFiles}
                        files={contactFiles}
                        maxFiles={1}
                    />

                    <div className="form__note">
                        {t('masterDataConfig.csvNote')} <br />
                        {CONTACT_COLUMNS.join(', ')}
                        <br />
                        <a target="_blank" href="/static/samples/csv/contact_list.csv">
                            {t('masterDataConfig.downloadSampleFile')}
                        </a>
                    </div>

                    <div className="form__group">
                        <ProgressButton
                            label={t('masterDataConfig.uploadContactListSubmit')}
                            onClick={handleContactListUpload}
                            disabled={!contactFiles.length}
                        />
                    </div>
                </div>
            )}

            {config?.orderMatchingLookupDefinitionId && (
                <div className="form file-form">
                    {orderMatchingUploadError && (
                        <Alert severity="error" className="alert--no-margin">
                            <AlertTitle>{orderMatchingUploadError}</AlertTitle>
                        </Alert>
                    )}

                    <DropArea
                        placeholder={t('masterDataConfig.uploadOrderMatchingListPlaceholder')}
                        accept={{ 'text/csv': ['.csv'] }}
                        onFilesChange={setOrderMatchingFiles}
                        files={orderMatchingFiles}
                        maxFiles={1}
                    />

                    <div className="form__note">
                        {t('masterDataConfig.csvNote')} <br />
                        {ORDER_MATCHING_COLUMNS.join(', ')}
                        <br />
                        <a target="_blank" href="/static/samples/csv/order_matching_list.csv">
                            {t('masterDataConfig.downloadSampleFile')}
                        </a>
                    </div>

                    <div className="form__group">
                        <ProgressButton
                            label={t('masterDataConfig.uploadOrderMatchingListSubmit')}
                            onClick={handleOrderMatchingListUpload}
                            disabled={!orderMatchingFiles.length}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
