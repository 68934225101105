import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ConfirmModal from '../../../core/containers/ConfirmModal';
import { url } from '../../../core/utils/link';
import { useTaskFilters } from '../../../core/utils/filterQuery';
import { AUTO_UPDATE_INTERVAL } from '../../../../constants';
import { canUseSupportMode } from '../../../users/utils';
import { useCurrentChannel } from '../../../core/utils/hooks/useCurrentChannel';
import { capitalizeFirstLetter } from '../../../utils/string';
import { PROCESSING_STATUS_COLOR_CLASS } from '../../../assistance/constants';
import { GET_NAVIGATION_CHANNELS } from '../../../core_updated/components/Navigation/internal/queries';
import RecordTable, {
    RecordTableColumn,
    ROW_CONTROLS_KEY,
    RowControlIconButton,
    RowControlIconButtonProps,
} from '../../../assistance/containers/RecordTable';
import Pagination from '../../../core_updated/components/Pagination';
import DropdownMenu from '../../../core_updated/components/DropdownMenu';
import { withIcon } from '../../../core_updated/components/Icon';
import {
    faCheckCircle,
    faEllipsisVertical,
    faExternalLink,
    faLock,
    faSpinnerThird,
    faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';
import Page from '../../../core_updated/components/Page';
import classnames from '../../../core_updated/utils/classnames';
import { useToaster } from '../../../core/components/Toast';
import { formatSender, formatSubject, formatTime } from '../../../assistance/containers/Overview';
import { useSteprunInfo } from '../../../core/utils/steprunConfig';
import Tooltip from '../../../core/components/Tooltip';
import { camelCase } from 'lodash';
import {
    FINISHED_STATUS_FILTER_CHOICES,
    FINISHED_STATUS_FILTER_CHOICES_WITH_DELETED,
    OPEN_STATUS_FILTER_CHOICES,
    StatusIndicator,
    TESTING_STATUS_FILTER_CHOICES,
} from './internal/StatusFilter';
import UploadFileModal from './internal/UploadFileModal';
import { AllChannelsHeader, SingleChannelHeader } from './internal/Header';
import FilterBar from './internal/FilterBar';
import { OverviewContextProvider } from './internal/OverviewContext';
import AddChannelModal from './internal/AddChannelModal';
import EmptyState from './internal/EmptyState';
import { useApplicationContext } from '../../../core_updated/contexts/ApplicationContext';
import AutomationRateModal from './internal/AutomationRateModal';
import { appInsights } from '../../../core/analytics/applicationInsights';
import { deleteRecordEvent, retryDocumentEvent, reUploadDocumentEvent } from '../../../core/analytics/customEvents';

const VALUES_MATCH_TRANSLATION_KEY = 'values_match';

const MoreIcon = withIcon(faEllipsisVertical);
const ExternalLinkIcon = withIcon(faExternalLink);
const LoadingIcon = withIcon(faSpinnerThird);
const LockedIcon = withIcon(faLock);
const MatchIcon = withIcon(faCheckCircle);
const NoMatchIcon = withIcon(faTimesCircle);

const isRecordLockedByUser = (record, user) => record.lockedBy && record.lockedBy?.email !== user?.email;

const MoreButton = ({
    record,
    showDebug,
    onRetryStep,
    onDebugStep,
    onDelete,
    ...props
}: RowControlIconButtonProps & {
    record: any;
    showDebug: boolean;
    onRetryStep: any;
    onDebugStep: any;
    onDelete: any;
}) => {
    const { t } = useTranslation('assistance');
    const { user } = useApplicationContext();

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const { steprunConfig, executionStatus } = useSteprunInfo(record, record?.stepRun);

    const isLocked = isRecordLockedByUser(record, user);
    const isFailed = ['FAILED'].includes(executionStatus);
    const canRetry = steprunConfig?.canRetry ?? true;
    const canMarkAsCompleted = steprunConfig?.canMarkAsCompleted ?? false;

    return (
        <>
            <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                    <RowControlIconButton {...props}>
                        <MoreIcon />
                    </RowControlIconButton>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content align="end">
                    {isFailed && onRetryStep && canRetry && (
                        <DropdownMenu.Item onClick={onRetryStep}>
                            {t('overview.recordRow.actions.retry')}
                        </DropdownMenu.Item>
                    )}
                    {isFailed && onRetryStep && canMarkAsCompleted && (
                        // Note: This only works for the pass-through import step right now
                        <DropdownMenu.Item onClick={onRetryStep}>
                            {t('overview.recordRow.actions.markAsCompleted')}
                        </DropdownMenu.Item>
                    )}
                    {onRetryStep && canRetry && showDebug && (
                        <DropdownMenu.Item onClick={onDebugStep}>
                            {t('overview.recordRow.actions.debug')}
                        </DropdownMenu.Item>
                    )}
                    <DropdownMenu.Item
                        className="text-error hover:text-error hover:!bg-error"
                        onClick={() => setDeleteModalVisible(true)}
                        disabled={isLocked}
                    >
                        {t('overview.recordRow.actions.delete')}
                    </DropdownMenu.Item>
                </DropdownMenu.Content>
            </DropdownMenu>
            <ConfirmModal
                onConfirm={() => {
                    setDeleteModalVisible(false);
                    return onDelete?.();
                }}
                onCancel={() => setDeleteModalVisible(false)}
                visible={deleteModalVisible}
                danger
            >
                <p>{t('overview.recordRow.deleteModalText')}</p>
            </ConfirmModal>
        </>
    );
};

const StatusBadge = ({ status, className }: any) => {
    const { t } = useTranslation('assistance');
    const statusColorGroup = PROCESSING_STATUS_COLOR_CLASS[status];

    return (
        <span
            className={classnames(
                'inline-flex rounded text-xs font-medium items-baseline px-2 py-1 gap-2 bg-secondary text-primary border border-solid border-secondary',
                statusColorGroup == 'success' && 'bg-success text-success border-success',
                statusColorGroup == 'warning' && 'bg-warning text-warning border-warning',
                statusColorGroup == 'error' && 'bg-error text-error border-error',
                className
            )}
        >
            <StatusIndicator status={status} />
            {t(`overview.filters.status.options.${status}`)}
        </span>
    );
};

const getArchivedInfo = (record) => {
    if (record.deletedAt) {
        return {
            archivedAt: record.deletedAt,
            archivedBy: record.deletedBy
                ? {
                      email: record.deletedBy.email,
                      name: `${record.deletedBy.firstName} ${record.deletedBy.lastName}`.trim(),
                  }
                : null,
        };
    } else if (record.discardRecord) {
        return {
            archivedAt: record.discardRecord.createdAt,
            archivedBy: record.discardRecord.user
                ? {
                      email: record.discardRecord.user.email,
                      name: `${record.discardRecord.user.firstName} ${record.discardRecord.user.lastName}`.trim(),
                  }
                : null,
        };
    } else if (record.finishedAt) {
        return {
            archivedAt: record.finishedAt,
            archivedBy: record.assistedBy
                ? {
                      email: record.assistedBy.email,
                      name: `${record.assistedBy.firstName} ${record.assistedBy.lastName}`.trim(),
                  }
                : null,
        };
    }
};

const UserCell = ({ user }) => {
    if (!user?.name) {
        return user.email;
    }

    return (
        <span className="inline-flex flex-col gap-1 text-primary">
            <span>{user.name}</span>
            <span className="text-xs text-tertiary">{user.email}</span>
        </span>
    );
};

const DocumentOverview = ({ documentConfiguration, props }) => {
    const documentName = capitalizeFirstLetter(documentConfiguration.documentTypeName);

    const { user, finished, showTestingDocuments, onDarkMode, isDarkMode, onLogout } = props;
    const { t, i18n } = useTranslation('assistance');

    const navigate = useNavigate();
    const { channelId } = useCurrentChannel();

    const [uploadFileModalVisible, setUploadFileModalVisible] = useState(false);
    const [addChannelVisible, setAddChannelVisible] = useState(false);
    const [activePage, setActivePage] = useState(0);
    const [duplicateChannelVisible, setDuplicateChannelVisible] = useState(false);
    const [automationRateModalVisible, setAutomationRateModalVisible] = useState(false);

    // By default do not show testing documents in the overview page
    const testing = showTestingDocuments || false;
    const [{ queryFilters, userFilters }, { setUserFilters }] = useTaskFilters({
        user,
        channelId,
        finished,
        testing,
    });

    // remove status filter if invalid for selected tab
    const statusFilterChoices =
        !testing && !finished
            ? OPEN_STATUS_FILTER_CHOICES
            : !testing
              ? FINISHED_STATUS_FILTER_CHOICES
              : TESTING_STATUS_FILTER_CHOICES;

    const isStatusFilterValid = userFilters.find((f) => f.name === 'status')?.value in statusFilterChoices;

    const [uploadFile] = useMutation(documentConfiguration.UPLOAD_FILE);
    const [reUploadFile] = useMutation(documentConfiguration.RE_UPLOAD_FILE);
    const [deleteRecord] = useMutation(documentConfiguration.DELETE_RECORD);
    const [createChannel] = useMutation(documentConfiguration.CREATE_CHANNEL);
    const [retryStep] = useMutation(documentConfiguration.RETRY_STEP);

    const itemsPerPage = documentConfiguration.constants?.ITEMS_PER_PAGE || 10;
    const {
        data,
        previousData,
        error,
        loading,
        refetch: tableRefetch,
        client,
    } = useQuery(documentConfiguration.GET_TABLE_OVERVIEW_DATA, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: false,
        pollInterval: AUTO_UPDATE_INTERVAL,
        variables: {
            filters: isStatusFilterValid ? queryFilters : queryFilters.filter((f) => f.name !== 'status'),
            offset: activePage * itemsPerPage,
            first: itemsPerPage,
            channelId: channelId,
        },
    });

    useEffect(() => {
        // Note: we cannot do this in onCompleted, because it is not called when notifyOnNetworkStatusChange is false

        // New data is available; trigger refetch on navigation to have matching numbers of open documents
        if (JSON.stringify(data) !== JSON.stringify(previousData)) {
            client.refetchQueries({
                include: [GET_NAVIGATION_CHANNELS],
            });
        }
    }, [data]);

    const handleUploadDone = (isTestingDocument) => {
        if (isTestingDocument) {
            navigate(
                channelId
                    ? url(documentConfiguration.constants.CHANNEL_TESTING_PATH, { channelId })
                    : url(documentConfiguration.constants.OVERVIEW_TESTING_PATH)
            );
        } else {
            navigate(
                channelId
                    ? url(documentConfiguration.constants.CHANNEL_PATH, { channelId })
                    : url(documentConfiguration.constants.OVERVIEW_PATH)
            );
        }
        setUserFilters([]);
        void tableRefetch();
        setUploadFileModalVisible(false);
    };

    const handleUploadCancel = () => {
        setUploadFileModalVisible(false);
    };

    const handleDeleteRecord = (record) => {
        appInsights?.trackEvent(
            ...deleteRecordEvent(user, record, record?.[documentConfiguration.documentTypeName + 'Unsaved'])
        );
        return deleteRecord({
            variables: {
                recordId: record.id,
            },
        }).then((res) => {
            void tableRefetch();
        });
    };

    const handleRetryStep = (record) => {
        appInsights?.trackEvent(
            ...retryDocumentEvent(user, record, record?.[documentConfiguration.documentTypeName + 'Unsaved'])
        );
        return retryStep({
            variables: {
                recordId: record.id,
                stepRunId: record.stepRun.id,
            },
            fetchPolicy: 'no-cache',
        }).then((res) => {
            void tableRefetch();
        });
    };

    const handleReUploadFile = (record) => {
        appInsights?.trackEvent(
            ...reUploadDocumentEvent(user, record, record?.[documentConfiguration.documentTypeName + 'Unsaved'])
        );
        navigate(
            channelId
                ? url(documentConfiguration.constants.CHANNEL_TESTING_PATH, { channelId })
                : url(documentConfiguration.constants.OVERVIEW_TESTING_PATH)
        );
        return reUploadFile({
            variables: {
                files: [],
                recordId: record?.id,
                channelId: record?.channel?.id,
                isTestingDocument: true,
            },
            fetchPolicy: 'no-cache',
        }).then((res) => {
            void tableRefetch();
        });
    };

    const handleCreateChannel = ({ name, fromExistingChannelId }) => {
        return createChannel({
            variables: {
                name,
                fromExistingChannelId,
            },
        }).then((res) => {
            const channelId = res?.data?.['create' + documentName + 'ProcessingChannel']?.channel?.id;
            navigate(url(documentConfiguration.constants.CHANNEL_CONFIG_PATH, { channelId }));
        });
    };

    const { publishToast } = useToaster();

    const handleWriteClipboard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => publishToast({ description: t('overview.header.actions.copyEmailAddressSuccess') }))
            .catch(() => publishToast({ description: t('overview.header.actions.copyEmailAddressError') }));
    };

    const currentData = loading ? previousData : data;

    const channels = currentData?.[documentConfiguration.documentTypeName + 'ProcessingChannels'] || [];
    const activeChannel = channels.find((channel) => channel.id === channelId);

    const totalCount = currentData?.['filtered' + documentName + 'ProcessingRecords']?.totalCount;
    const records =
        currentData?.['filtered' + documentName + 'ProcessingRecords']?.edges?.map((e) => ({
            key: e.node.id,
            disabled: !!(isRecordLockedByUser(e.node, user) || e.node.status === 'PENDING'),
            ...e.node,
        })) || [];

    const urlSearchParams = {};
    if (channelId) urlSearchParams['channel'] = channelId;

    let columns: RecordTableColumn[] = [
        {
            key: 'document',
            label: t('overview.columns.document'),
            render: (record) => {
                const isLocked = isRecordLockedByUser(record, user);
                const isLoading = ['PENDING', 'SCHEDULED', 'RUNNING', 'ASSISTED'].includes(record.status);

                const externalReference = record?.deliveryRecord?.externalReference;
                const externalMessage = record?.deliveryRecord?.externalMessage;
                const externalStatus = record?.deliveryRecord?.externalStatus;

                const externalParts = [
                    externalReference && `${t('overview.recordRow.externalReference')}: ${externalReference}`,
                    externalStatus && `${t('overview.recordRow.externalStatus')}: ${externalStatus}`,
                    externalMessage && `${t('overview.recordRow.externalMessage')}: ${externalMessage}`,
                ].filter((part) => !!part);

                return (
                    <Link
                        className="inline-flex gap-3 items-center w-full"
                        to={url(
                            documentConfiguration.constants.ASSISTANCE_PATH,
                            { recordId: record.id },
                            { search: urlSearchParams }
                        )}
                    >
                        {isLoading && <LoadingIcon spin className="text-brand" />}
                        {!isLoading && isLocked && <LockedIcon className="text-secondary" />}
                        <span className="inline-flex flex-col gap-1 text-primary">
                            <span>{formatSender(record)}</span>
                            {record['subject'] && (
                                <span className="text-xs text-tertiary">{formatSubject(record)}</span>
                            )}
                            {externalParts.length > 0 && (
                                <span className="text-xs text-tertiary">{externalParts.join('  -  ')}</span>
                            )}
                        </span>
                    </Link>
                );
            },
            className: 'min-w-96',
        },
        {
            key: 'status',
            label: t('overview.columns.status'),
            render: (record) => (
                <StatusBadge
                    status={(() => {
                        if (record?.deletedAt) {
                            return FINISHED_STATUS_FILTER_CHOICES_WITH_DELETED.DELETED;
                        } else if (testing && record?.isTestDocument && record?.canProcessAutomatically) {
                            // testing documents always go to assistance, however,
                            // those testing docs that would go through automatically should be labelled as such
                            return TESTING_STATUS_FILTER_CHOICES.FINISHED_TESTING;
                        } else {
                            return record.status;
                        }
                    })()}
                />
            ),
            className: 'min-w-60 w-60',
        },
        {
            key: 'createdAt',
            label: t('overview.columns.createdAt'),
            render: (record) => formatTime(record.createdAt),
            className: 'min-w-60 w-60',
        },
    ];

    if (finished) {
        columns.push({
            key: 'archivedBy',
            label: t('overview.columns.archivedBy'),
            render: (record) => {
                const archivedInfo = getArchivedInfo(record);
                if (!archivedInfo) return '';

                if (!archivedInfo.archivedBy) {
                    return t('overview.archivedByAutomated');
                }

                return <UserCell user={archivedInfo.archivedBy} />;
            },
            className: 'min-w-60 w-60',
        });

        columns.push({
            key: 'archivedAt',
            label: t('overview.columns.archivedAt'),
            render: (record) => (getArchivedInfo(record) ? formatTime(getArchivedInfo(record).archivedAt) : ''),
            className: 'min-w-60 w-60',
        });
    }

    columns.push({
        key: ROW_CONTROLS_KEY,
        label: '',
        render: (record) => {
            return (
                <div className="flex gap-2">
                    <Tooltip content={t('overview.recordRow.openInNewTab')} placement="bottom">
                        <RowControlIconButton asChild>
                            <Link
                                to={url(
                                    documentConfiguration.constants.ASSISTANCE_PATH,
                                    { recordId: record.id },
                                    { search: urlSearchParams }
                                )}
                                target="_blank"
                            >
                                <ExternalLinkIcon />
                            </Link>
                        </RowControlIconButton>
                    </Tooltip>
                    <MoreButton
                        record={record}
                        showDebug={canUseSupportMode(user)}
                        onRetryStep={() => handleRetryStep(record)}
                        onDebugStep={() => handleReUploadFile(record)}
                        onDelete={() => handleDeleteRecord(record)}
                    />
                </div>
            );
        },
        className: 'min-w-28 w-28',
    });

    if (!channelId) {
        // Add channel column to all channels overview table
        const channelColumn = {
            key: 'channel',
            label: t('overview.columns.channel'),
            render: (record) => record.channel.name,
            className: 'min-w-60 w-60',
        };

        const createdAtColumnIndex = columns.findIndex((c) => c.key === 'createdAt');
        columns.splice(createdAtColumnIndex + 1, 0, channelColumn);
    }

    if (documentConfiguration?.documentMatchingValidationKey) {
        const matchingColumn = {
            key: 'matching',
            label: t(`overview.columns.${camelCase(documentConfiguration?.documentMatchingValidationKey)}`),
            render: (record) => {
                const isMatchingEnabled =
                    documentConfiguration?.documentMatchingValidationKey &&
                    record?.channel?.extractorConfig?.validationChecks?.includes(
                        documentConfiguration?.documentMatchingValidationKey
                    );

                // Overview matching label should only be visible when matching feature is enabled
                // and the document is not finished
                if (!isMatchingEnabled)
                    return <span className="text-secondary">{t('overview.recordRow.matchingStatus.notEnabled')}</span>;
                if (finished) return null;

                const document = record?.[documentConfiguration.documentTypeName + 'Unsaved'];
                const documentNumberField = document?.[documentConfiguration.documentMatchingFieldName];
                const explanation = documentNumberField?.confidenceExplanation;
                const isMatching =
                    explanation?.explanationDetails?.document_matching_result === VALUES_MATCH_TRANSLATION_KEY;
                const documentNumber = documentNumberField?.value || '';
                let updatedAt = explanation?.explanationDetails?.['time_stamp'] || record?.createdAt || '';
                if (updatedAt) updatedAt = moment(updatedAt).format('lll');

                return (
                    <Tooltip
                        content={t(`overview.recordRow.matchingStatus.${isMatching ? 'match' : 'noMatch'}`, {
                            documentNumber,
                            updatedAt,
                        })}
                        placement="bottom"
                    >
                        <span className="inline-flex gap-2 items-center font-medium">
                            {isMatching ? (
                                <MatchIcon className="text-confidence-high" />
                            ) : (
                                <NoMatchIcon className="text-confidence-low" />
                            )}

                            {t(`overview.recordRow.matchingStatusShort.${isMatching ? 'match' : 'noMatch'}`)}
                        </span>
                    </Tooltip>
                );
            },
            className: 'min-w-60 w-60',
        };

        // insert matching column after status column
        const statusColumnIndex = columns.findIndex((c) => c.key === 'status');
        columns.splice(statusColumnIndex + 1, 0, matchingColumn);
    }

    return (
        <OverviewContextProvider
            finished={finished}
            testing={testing}
            channelId={channelId}
            documentConfiguration={documentConfiguration}
        >
            <Page>
                <AddChannelModal
                    visible={addChannelVisible}
                    onConfirm={handleCreateChannel}
                    onCancel={() => setAddChannelVisible(false)}
                />

                <AddChannelModal
                    visible={duplicateChannelVisible}
                    onConfirm={(formData) =>
                        handleCreateChannel({ name: formData.name, fromExistingChannelId: channelId })
                    }
                    onCancel={() => setDuplicateChannelVisible(false)}
                    title={t('overview.header.actions.duplicateChannel')}
                    initialValue={t('overview.header.actions.duplicateChannelNamePrefix') + activeChannel?.name}
                />

                <UploadFileModal
                    visible={uploadFileModalVisible}
                    activeChannel={activeChannel}
                    uploadFile={uploadFile}
                    onUploadDone={handleUploadDone}
                    channels={channels}
                    onCancel={handleUploadCancel}
                />

                <AutomationRateModal
                    visible={automationRateModalVisible}
                    onClose={() => {
                        setAutomationRateModalVisible(false);
                    }}
                    channelName={activeChannel?.name}
                    channelId={channelId}
                    documentName={documentName}
                    documentConfiguration={documentConfiguration}
                />

                {channelId ? (
                    <SingleChannelHeader
                        activeChannel={activeChannel}
                        onUploadFileModalVisibleChange={setUploadFileModalVisible}
                        onDuplicateChannelVisibleChange={setDuplicateChannelVisible}
                        onAutomationRateModalVisibleChange={setAutomationRateModalVisible}
                        onCopyEmailAddress={handleWriteClipboard}
                        documentConfiguration={documentConfiguration}
                    />
                ) : (
                    <AllChannelsHeader
                        user={user}
                        onAddChannelVisibleChange={setAddChannelVisible}
                        onAutomationRateModalVisibleChange={setAutomationRateModalVisible}
                    />
                )}

                <FilterBar
                    filters={isStatusFilterValid ? userFilters : userFilters.filter((f) => f.name !== 'status')}
                    onFiltersChange={setUserFilters}
                    renderExtraFilters={documentConfiguration.renderExtraFilters}
                    activeChannel={activeChannel}
                />

                <Page.Content lowered className="flex flex-col gap-6 ">
                    {!loading && totalCount === 0 ? (
                        <EmptyState filters={userFilters} onFiltersChange={setUserFilters} />
                    ) : (
                        <>
                            <div
                                className={classnames(
                                    'flex flex-col gap-6 transition-all',
                                    loading && 'animate-pulse opacity-50'
                                )}
                            >
                                <RecordTable columns={columns} records={records} loading={loading} />
                            </div>

                            {totalCount > 0 && (
                                <div className="flex justify-between">
                                    <span className="text-sm text-secondary">
                                        {activePage * itemsPerPage + 1} -{' '}
                                        {Math.min(totalCount, (activePage + 1) * itemsPerPage)} of {totalCount}
                                    </span>

                                    <Pagination
                                        numItems={totalCount}
                                        itemsPerPage={itemsPerPage}
                                        activePage={activePage}
                                        onChange={setActivePage}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </Page.Content>
            </Page>
        </OverviewContextProvider>
    );
};

export default DocumentOverview;
