import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import classnames from '../utils/classnames';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = React.forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, style, align = 'center', sideOffset = 4, ...props }, ref) => (
    <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
            ref={ref}
            align={align}
            sideOffset={sideOffset}
            className={classnames(
                'z-50 w-72 rounded-lg border border-solid border-primary bg-primary p-4 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 overflow-auto',
                className
            )}
            style={{
                maxHeight: 'calc(var(--radix-popover-content-available-height) - 10px)',
                minWidth: 'var(--radix-popover-trigger-width)',
                ...style,
            }}
            onWheel={(e) => {
                e.stopPropagation();
            }}
            onClick={(e) => {
                e.stopPropagation();
            }}
            {...props}
        />
    </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export default Object.assign(Popover, {
    Trigger: PopoverTrigger,
    Content: PopoverContent,
    Anchor: PopoverPrimitive.Anchor,
});
