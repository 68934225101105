import DocumentConfiguration, { CONFIG_OPTIONS, DEFAULT_CONFIGS } from '../../../generic_document/pages/Configuration';
import * as DocumentQueries from './queries';
import { GET_OVERVIEW_DATA } from '../../queries';
import { CHANNEL_PATH, OVERVIEW_PATH } from '../../constants';
import React from 'react';
import { DocumentType } from '../../../generic_document/constants';

const Configuration = (props) => {
    const configOptions = {
        [CONFIG_OPTIONS.Document]: DEFAULT_CONFIGS[CONFIG_OPTIONS.Document],
        [CONFIG_OPTIONS.MasterData]: DEFAULT_CONFIGS[CONFIG_OPTIONS.MasterData],
    };

    const documentConfiguration = {
        service: 'list_of_services',
        documentType: DocumentType.ListOfServices,
        documentTypeName: 'listOfServices',
        activeTab: 'list-of-services',
        documentQueries: DocumentQueries,
        getOverviewData: GET_OVERVIEW_DATA,
        channelPath: CHANNEL_PATH,
        overviewPath: OVERVIEW_PATH,
        configOptions: configOptions,
    };

    return <DocumentConfiguration documentConfiguration={documentConfiguration} {...props} />;
};

export default Configuration;
