import { withIcon } from '../../../core_updated/components/Icon';
import {
    faCode,
    faDatabase,
    faExclamationCircle,
    faFile,
    faGearCode,
    faQuestionCircle,
    faSparkles,
    faUser,
} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import {
    isoDateFormat,
    isoDateTimeFormat,
    isoDateTimeFormatWithMcsAndTimezone,
    isoDateTimeFormatWithMs,
    isoDateTimeFormatWithMsAndTimezone,
    isoDateTimeFormatWithTimezone,
    isoTimeFormat,
} from '../../../utils/dates';
import { useTranslation } from 'react-i18next';

const DatabaseIcon = withIcon(faDatabase);
const MagicIcon = withIcon(faSparkles);
const UserIcon = withIcon(faUser);
const DocumentIcon = withIcon(faFile);
const CustomizationIcon = withIcon(faGearCode);
const ErrorIcon = withIcon(faExclamationCircle);
const UnknownIcon = withIcon(faQuestionCircle);

const SUPPORTED_DATE_FORMATS = [
    isoDateFormat,
    isoDateTimeFormat,
    isoDateTimeFormatWithTimezone,
    isoTimeFormat,
    isoDateTimeFormatWithMs,
    isoDateTimeFormatWithMsAndTimezone,
    isoDateTimeFormatWithTimezone,
    isoDateTimeFormatWithMcsAndTimezone,
];

const localizeExplanationDetails = (t: any, explanationDetails: any, dateFormat: string = 'L') => {
    if (!explanationDetails) return explanationDetails;

    const dateFieldNames = ['field_value', 'expected_value', 'obtained_value', 'time_stamp'];
    for (const dateFieldName of dateFieldNames) {
        if (
            explanationDetails?.[dateFieldName] &&
            moment(explanationDetails?.[dateFieldName], SUPPORTED_DATE_FORMATS, true).isValid()
        ) {
            explanationDetails[dateFieldName] = moment(
                explanationDetails[dateFieldName],
                SUPPORTED_DATE_FORMATS,
                true
            ).format(dateFormat);
        }
    }

    explanationDetails['threshold_unit'] =
        explanationDetails?.threshold_matching === 'date' ? t(`assistance:explanation.threshold_unit_days`) : '';

    return explanationDetails;
};

const legacyExplanationDetails = (translationKey: string, explanationDetails: any): [string, any] => {
    // Basically we changed a translation so the old values wouldn't be found anymore
    // If you add more cases please make sure to add the date as well so we can remove it later

    // 2024-07-04: split between multiple_good_results and multiple_good_article_results
    if (translationKey === 'explanation.multiple_good_results' && explanationDetails?.article_number1) {
        return ['explanation.multiple_good_article_results', explanationDetails];
    }

    return [translationKey, explanationDetails];
};

enum MatchingResult {
    no_document_found = 'no_document_found',
    no_document_item_found = 'no_document_item_found',
    values_not_match = 'values_not_match',
    values_match = 'values_match',
    value_in_range = 'value_in_range',
    deviation_accepted = 'deviation_accepted',
    ignore_matching = 'ignore_matching',
}

const documentMatchingTextMessagesAndStyles = {
    no_document_found: ['error', 'OC-01'], // case 1
    no_document_item_found: ['error', 'OC-02'], // case 2
    field_matching_required_or_optional_matching_ignored: ['neutral', 'OC-12'], // case 23
    field_mandatory__matching_required__field_empty: ['error', 'OC-10'], // case 12
    field_mandatory__matching_optional__field_empty: ['neutral', 'OC-10'], // case 13
    field_mandatory__matching_required__threshold__field_not_empty__values_match: ['success', 'OC-03'], // case 6
    field_mandatory__matching_required__threshold__field_not_empty__value_in_range: ['warning', 'OC-06'], // case 7
    field_mandatory__matching_required__threshold__field_not_empty__values_not_match: ['error', 'OC-11'], // case 8
    field_mandatory__matching_required__threshold__field_not_empty__deviation_accepted: ['success', 'OC-05'], // case 9
    field_mandatory__matching_required__field_not_empty__values_match: ['success', 'OC-03'], // case 3
    field_mandatory__matching_required__field_not_empty__values_not_match: ['error', 'OC-04'], // case 4
    field_mandatory__matching_required__field_not_empty__deviation_accepted: ['success', 'OC-05'], // case 5
    field_mandatory__matching_optional__field_not_empty__values_match: ['neutral', 'OC-07'], // case 10
    field_mandatory__matching_optional__field_not_empty__values_not_match: ['warning', 'OC-09'], // case 11
    field_optional__matching_required__threshold__field_not_empty__values_match: ['success', 'OC-03'], // case 17
    field_optional__matching_required__threshold__field_not_empty__value_in_range: ['warning', 'OC-06'], // case 18
    field_optional__matching_required__threshold__field_not_empty__values_not_match: ['error', 'OC-11'], // case 19
    field_optional__matching_required__threshold__field_not_empty__deviation_accepted: ['success', 'OC-05'], // case 20
    field_optional__matching_required__field_not_empty__values_match: ['success', 'OC-03'], // case 14
    field_optional__matching_required__field_not_empty__values_not_match: ['error', 'OC-04'], // case 15
    field_optional__matching_required__field_not_empty__deviation_accepted: ['success', 'OC-05'], // case 16
    field_optional__matching_optional__field_not_empty__values_match: ['neutral', 'OC-07'], // case 21
    field_optional__matching_optional__field_not_empty__values_not_match: ['neutral', 'OC-09'], // case 22
    // added cases
    // field_optional__matching_required__field_empty: ['error', 'OC-10'], // case 12
    // field_optional__matching_optional__field_empty: ['neutral', 'OC-10'], // case 13
};

const documentMatchingAcceptDeviationVisible = [
    'field_mandatory__matching_required__field_not_empty__values_not_match', // case 4
    'field_mandatory__matching_required__threshold__field_not_empty__value_in_range', // case 7
    'field_mandatory__matching_required__threshold__field_not_empty__values_not_match', // case 8
    'field_optional__matching_required__field_not_empty__values_not_match', // case 15
    'field_optional__matching_required__threshold__field_not_empty__value_in_range', // case 18
    'field_optional__matching_required__threshold__field_not_empty__values_not_match', // case 19
    'field_matching_required_or_optional_matching_ignored', // case 23
];

const documentMatchingActions = {
    field_mandatory__matching_required__field_not_empty__values_not_match: 'document_matching_accept_deviation', // case 4
    field_mandatory__matching_required__threshold__field_not_empty__value_in_range:
        'document_matching_accept_deviation', // case 7
    field_mandatory__matching_required__threshold__field_not_empty__values_not_match:
        'document_matching_accept_deviation', // case 8
    field_optional__matching_required__field_not_empty__values_not_match: 'document_matching_accept_deviation', // case 15
    field_optional__matching_required__threshold__field_not_empty__value_in_range: 'document_matching_accept_deviation', // case 18
    field_optional__matching_required__threshold__field_not_empty__values_not_match:
        'document_matching_accept_deviation', // case 19
    field_matching_required_or_optional_matching_ignored: 'document_matching_restore_matching', // case 23
};

export const getDocumentMatchingKey = (explanationDetails: any, isFieldRequired = false, isFieldEmpty = false) => {
    const matchingResult: MatchingResult = explanationDetails?.document_matching_result;

    const isMatchingRequired: boolean = explanationDetails?.block_automation;
    const hasThreshold = explanationDetails?.comparison_type !== 'exact_match';

    let key = '';

    if (matchingResult === 'no_document_found') {
        key = 'no_document_found';
    } else if (matchingResult === 'no_document_item_found') {
        key = 'no_document_item_found';
    } else if (matchingResult === 'ignore_matching') {
        key = 'field_matching_required_or_optional_matching_ignored'; // case 23
    } else {
        // we don't need to check for not supported as only the list above is supported
        key += isFieldRequired ? 'field_mandatory' : 'field_optional';
        key += isMatchingRequired ? '__matching_required' : '__matching_optional';
        key += hasThreshold ? '__threshold' : '';
        key += isFieldEmpty ? '__field_empty' : '__field_not_empty';
        key += !isFieldEmpty ? '__' + matchingResult : '';
    }

    return key;
};

const getDocumentMatchingTextMessagesAndStyles = (key: string) => {
    // previously "not_supported" -> instead of red error we fall back to extraction logic and show neutral
    return documentMatchingTextMessagesAndStyles[key] || ['neutral', ''];
};

export const getDocumentMatchingSeverity = (key: string) => {
    return getDocumentMatchingTextMessagesAndStyles(key)[0];
};

export const useDocumentMatchingExplanationMessage = (
    confidenceExplanation: any,
    dateFormat: string,
    isFieldRequired = false,
    isFieldEmpty = false
) => {
    const { t } = useTranslation('assistance');

    const explanationDetails: any = confidenceExplanation?.explanationDetails || {};
    explanationDetails['threshold_unit'] = t(`assistance:explanation.${explanationDetails?.comparison_type}`);

    const matchingResult: MatchingResult = explanationDetails?.document_matching_result;
    if (matchingResult == null) {
        return { message: '', severity: '', acceptDeviationVisible: false, Icon: undefined };
    }

    const key = getDocumentMatchingKey(explanationDetails, isFieldRequired, isFieldEmpty);
    const [severity, translationKey] = getDocumentMatchingTextMessagesAndStyles(key);
    if (!translationKey) {
        // no document matching explanation available
        console.warn('No document matching explanation available', key);
        return { message: '', severity: '', acceptDeviationVisible: false, Icon: undefined };
    }

    const acceptVisible = documentMatchingAcceptDeviationVisible.includes(key);
    const acceptAction = documentMatchingActions[key];

    const localizedExplanationDetails = localizeExplanationDetails(t, explanationDetails, dateFormat);
    const message = translationKey ? t(`explanation.${translationKey}`, localizedExplanationDetails) : '';
    const Icon = severity === 'error' ? ErrorIcon : getExplanationIcon(confidenceExplanation.translationKey); // fallback to confidence explanation icon

    return { message, severity, acceptVisible, acceptAction, Icon };
};

const getExplanationIcon = (translationKey: any) => {
    if (
        [
            'explanation.master_data_match',
            'explanation.extracted_article_number_and_description',
            'explanation.extracted_article_description',
            'explanation.extracted_article_number',
            'explanation.multiple_good_results',
            'explanation.multiple_good_article_results',
        ].includes(translationKey)
    ) {
        return DatabaseIcon;
    } else if (translationKey === 'explanation.historical_matches') {
        return MagicIcon;
    } else if (translationKey === 'explanation.user_correction') {
        return UserIcon;
    } else if (['explanation.extracted_value', 'explanation.extracted_value_from_llm'].includes(translationKey)) {
        return DocumentIcon;
    } else if (['explanation.decimal_quantity', 'explanation.out_of_range_validation_error'].includes(translationKey)) {
        return ErrorIcon;
    } else if (translationKey === 'explanation.customization_info') {
        return CustomizationIcon;
    } else {
        return UnknownIcon;
    }
};

export const useConfidenceExplanationMessage = (confidenceExplanation: any, dateFormat: string) => {
    const { t } = useTranslation('assistance');

    const localizedExplanationDetails = localizeExplanationDetails(
        t,
        confidenceExplanation?.explanationDetails,
        dateFormat
    );
    const [translationKey, explanationDetails] = legacyExplanationDetails(
        confidenceExplanation.translationKey,
        localizedExplanationDetails
    );
    const message = translationKey ? t(`${translationKey}`, explanationDetails) : '';

    return { message, Icon: getExplanationIcon(translationKey) };
};
