import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { PROCESSING_STATUS_COLOR_CLASS } from '../../constants';
import { ERROR_RESOLUTIONS, useSteprunInfo } from '../../../core/utils/steprunConfig';
import { url } from '../../../core/utils/link';
import TextArea from '../../../core/components/TextArea';
import ConfirmModal from '../../../core/containers/ConfirmModal';
import { withIcon } from '../../../core_updated/components/Icon';
import {
    faArrowDownToArc,
    faArrowRotateLeft,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faCircleHalfStroke,
    faCode,
    faDatabase,
    faFileLines,
    faList,
    faRectangleVerticalHistory,
    faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import Tooltip from '../../../core/components/Tooltip';
import { useAssistanceContext } from '../../../generic_document/pages/Assistance/AssistanceContext';
import classnames from '../../../core_updated/utils/classnames';
import DropdownMenu from '../../../core_updated/components/DropdownMenu';
import { TABS_OPTIONS } from '../../../generic_document/pages/Assistance';
import { MasterdataVersionSummary } from '../../../masterdata/containers/MasterdataVersionSummary';

const ChevronDownIcon = withIcon(faChevronDown);
const SourceIcon = withIcon(faArrowDownToArc);
const DocumentIcon = withIcon(faFileLines);
const ItemsIcon = withIcon(faList);
const ResetChangesIcon = withIcon(faArrowRotateLeft);
const OriginalDataIcon = withIcon(faCircleHalfStroke);
const MasterDataIcon = withIcon(faDatabase);
const ChevronLeftIcon = withIcon(faChevronLeft);
const ChevronRightIcon = withIcon(faChevronRight);
const LoadingIcon = withIcon(faSpinnerThird);
const OutputFileIcon = withIcon(faRectangleVerticalHistory);

const StatusIndicator = ({ status, className }: any) => {
    const statusColorGroup = PROCESSING_STATUS_COLOR_CLASS[status];
    return (
        <div
            className={classnames(
                'rounded-full w-2.5 h-2.5 bg-tertiary flex-none',
                statusColorGroup == 'success' && 'bg-confidence-high',
                statusColorGroup == 'warning' && 'bg-confidence-medium',
                statusColorGroup == 'error' && 'bg-confidence-low',
                className
            )}
        />
    );
};

const DropdownMenuStatusItem = ({ status, active = false, className, ...props }: any) => {
    const { t } = useTranslation('assistance');
    const statusColorGroup = PROCESSING_STATUS_COLOR_CLASS[status];

    return (
        <DropdownMenu.Item
            {...props}
            className={classnames(
                'hover:bg-secondary hover:text-primary',
                'data-[disabled]:opacity-100', // Force overwrite default dropdown item disabled style
                active && 'bg-secondary text-primary',
                statusColorGroup == 'success' &&
                    'hover:bg-success hover:text-success active:bg-success active:text-success focus:bg-success focus:text-success',
                active && statusColorGroup == 'success' && 'bg-success text-success',
                statusColorGroup == 'warning' &&
                    'hover:bg-warning hover:text-warning active:bg-warning active:text-warning focus:bg-warning focus:text-warning',
                active && statusColorGroup == 'warning' && 'bg-warning text-warning',
                statusColorGroup == 'error' &&
                    'hover:bg-error hover:text-error active:bg-error active:text-error focus:bg-error focus:text-error',
                active && statusColorGroup == 'error' && 'bg-error text-error',
                className
            )}
        >
            <div className="flex items-center gap-2">
                <StatusIndicator status={status} />
                <span className="text-primary">{t(`assistance:processingStatus.${status}`)}</span>
            </div>
        </DropdownMenu.Item>
    );
};

export const AssistanceHeaderTitleGroup = () => {
    const { record, handlers, loading } = useAssistanceContext();
    const { t } = useTranslation('assistance');

    const startedAt = record?.createdAt;
    let title = startedAt && moment(startedAt).format('dddd, Do MMMM YYYY, HH:mm');
    title = title ? `${t('header.titleReceivedAt')} ${title}` : '';

    if (record?.subject) {
        title = record?.subject;
    }

    const status = record?.status;

    // On Hold stuff
    const assistableStatus = ['ASSISTANCE_NEEDED', 'ON_HOLD'].includes(status);
    const [onHoldModalVisible, setOnHoldModalVisible] = useState(false);
    const handleOnHoldConfirm = useCallback(
        (reason) => {
            return handlers?.onChangeStatus?.('ON_HOLD', reason).then(() => setOnHoldModalVisible(false));
        },
        [handlers?.onChangeStatus, record]
    );
    const handleOnHold = useCallback(() => setOnHoldModalVisible(true), []);
    const handleOnHoldReject = useCallback(() => setOnHoldModalVisible(false), []);

    // delete stuff
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    // render helper
    const titleRef = useRef(null);

    return (
        <>
            <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                    <button className="flex flex-shrink min-w-0 gap-3 rounded transition-colors hover:bg-secondary py-1.5 px-3 items-center cursor-pointer active:bg-brand data-[state=open]:bg-brand select-text">
                        <StatusIndicator status={status} />
                        <Tooltip className="flex-shrink min-w-0" content={title} triggerRef={titleRef} overflowOnly>
                            <h2
                                className="font-medium text-base text-primary truncate flex-shrink min-w-0 force-line-height"
                                ref={titleRef}
                            >
                                {title}
                            </h2>
                        </Tooltip>
                        {record?.isTestDocument ? (
                            <div className="rounded flex-none leading-none text-[0.7rem] py-1 px-1.5 uppercase font-bold text-inverted bg-brand-default">
                                {t('header.testDocument')}
                            </div>
                        ) : null}
                        {loading ? (
                            <LoadingIcon className="text-brand text-sm animate-spin" />
                        ) : (
                            <ChevronDownIcon className="text-primary text-sm" />
                        )}
                    </button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content align="start">
                    {assistableStatus ? (
                        <>
                            <DropdownMenuStatusItem
                                status="ASSISTANCE_NEEDED"
                                active={status === 'ASSISTANCE_NEEDED'}
                                onSelect={
                                    status !== 'ASSISTANCE_NEEDED'
                                        ? () => handlers?.onChangeStatus?.('ASSISTANCE_NEEDED')
                                        : undefined
                                }
                            />
                            <DropdownMenuStatusItem
                                status="ON_HOLD"
                                active={status === 'ON_HOLD'}
                                onSelect={status !== 'ON_HOLD' ? handleOnHold : undefined}
                            />
                        </>
                    ) : (
                        <DropdownMenuStatusItem status={status} active />
                    )}

                    <DropdownMenu.Separator />
                    <DropdownMenu.Item onSelect={() => handlers?.onDiscard()} disabled={!assistableStatus}>
                        {t('header.actions.discardDocument')}
                    </DropdownMenu.Item>
                    <DropdownMenu.Item
                        className="!text-error hover:bg-error focus:bg-error"
                        onSelect={() => setDeleteModalVisible(true)}
                    >
                        {t('header.actions.deleteDocument')}
                    </DropdownMenu.Item>
                </DropdownMenu.Content>
            </DropdownMenu>

            <OnHoldModal visible={onHoldModalVisible} onConfirm={handleOnHoldConfirm} onCancel={handleOnHoldReject} />

            <ConfirmModal
                title={t('deleteModal.title')}
                labelConfirm={t('deleteModal.confirmButton')}
                labelCancel={t('deleteModal.cancelButton')}
                onConfirm={() => {
                    setDeleteModalVisible(false);
                    return handlers?.onDelete?.();
                }}
                onCancel={() => setDeleteModalVisible(false)}
                visible={deleteModalVisible}
                danger
            >
                <p>{t('deleteModal.message')}</p>
            </ConfirmModal>
        </>
    );
};

const AssistanceHeaderViewsToggle = () => {
    const { t } = useTranslation('assistance');
    const { recordId, tab } = useParams();
    const { tabs, documentConfiguration } = useAssistanceContext();
    const tabNames = tabs ? Object.keys(tabs) : [];

    const getIcon = (tabName: TABS_OPTIONS) => {
        switch (tabName) {
            case TABS_OPTIONS.Source:
                return <SourceIcon />;
            case TABS_OPTIONS.Header:
                return <DocumentIcon />;
            case TABS_OPTIONS.Document:
                return <DocumentIcon />;
            default:
                return <ItemsIcon />;
        }
    };

    return (
        <div className="flex gap-1 select-none flex-none">
            {tabNames.map((tabName) => (
                <Link
                    key={tabName}
                    className={classnames(
                        'flex items-center gap-2 rounded transition-colors hover:bg-secondary py-1.5 px-3 cursor-pointer text-sm font-medium text-primary hover:text-primary',
                        tabName == tab && '!bg-brand !text-brand'
                    )}
                    to={url(
                        documentConfiguration.ASSISTANCE_TAB_PATH,
                        { recordId, tab: tabName },
                        { keepSearch: true }
                    )}
                >
                    {getIcon(tabName as TABS_OPTIONS)}
                    {t(`header.tabs.${tabName}`)}
                </Link>
            ))}
        </div>
    );
};

const AssistanceHeaderIconButton = React.forwardRef(({ className, active = false, ...props }: any, ref) => {
    return (
        <button
            className={classnames(
                'flex items-center justify-center gap-2 rounded transition-colors hover:bg-secondary w-8 h-8 cursor-pointer text-sm font-medium text-primary hover:text-primary disabled:opacity-50 disabled:pointer-events-none data-[state=open]:bg-brand data-[state=open]:text-brand',
                active && '!bg-brand !text-brand',
                className
            )}
            {...props}
            ref={ref}
        />
    );
});

const AssistanceHeaderIconLink = React.forwardRef(({ className, active = false, disabled, to, ...props }: any, ref) => {
    return (
        <Link
            className={classnames(
                'flex items-center justify-center gap-2 rounded transition-colors hover:bg-secondary w-8 h-8 cursor-pointer text-sm font-medium text-primary hover:text-primary data-[state=open]:bg-brand data-[state=open]:text-brand',
                active && '!bg-brand !text-brand',
                disabled && 'opacity-50 pointer-events-none',
                className
            )}
            {...props}
            to={to}
            ref={ref}
        />
    );
});

export const AssistanceHeaderControls = () => {
    const { t } = useTranslation('assistance');
    const { record, handlers } = useAssistanceContext();
    const status = record?.status;

    const [resetModalVisible, setResetModalVisible] = useState(false);

    const hasMasterdata = record?.masterdataVersionsExtracted && record?.masterdataVersionsExtracted?.length > 0;
    const assistableStatus = ['ASSISTANCE_NEEDED', 'ON_HOLD'].includes(status);

    return (
        <>
            <div className="flex gap-1 select-none">
                <Tooltip content={t('nextButton.resetButton')}>
                    <AssistanceHeaderIconButton onClick={() => setResetModalVisible(true)} disabled={!assistableStatus}>
                        <ResetChangesIcon />
                    </AssistanceHeaderIconButton>
                </Tooltip>
                <Tooltip
                    content={
                        handlers?.showOriginalData
                            ? t('header.alerts.executionFinished.showResult')
                            : t('header.alerts.executionFinished.showOriginal')
                    }
                >
                    <AssistanceHeaderIconButton
                        onClick={() => handlers?.onShowOriginalData(!handlers?.showOriginalData)}
                    >
                        <OriginalDataIcon className={classnames(handlers?.showOriginalData && '-scale-x-100')} />
                    </AssistanceHeaderIconButton>
                </Tooltip>
                <Tooltip
                    content={
                        hasMasterdata
                            ? t(`masterdata:versionSummary.tooltip`)
                            : t(`masterdata:versionSummary.tooltipDisabled`)
                    }
                >
                    <DropdownMenu>
                        <DropdownMenu.Trigger asChild>
                            <AssistanceHeaderIconButton disabled={!hasMasterdata}>
                                <MasterDataIcon />
                            </AssistanceHeaderIconButton>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content align="end">
                            <MasterdataVersionSummary versionInfo={record?.masterdataVersionsExtracted} />
                        </DropdownMenu.Content>
                    </DropdownMenu>
                </Tooltip>
                <Tooltip content={t('header.showOutputFileStatus')}>
                    <AssistanceHeaderIconButton
                        onClick={() => handlers?.onResultModalVisibleChange?.(true)}
                        active={handlers?.resultModalVisible}
                    >
                        <OutputFileIcon />
                    </AssistanceHeaderIconButton>
                </Tooltip>
            </div>

            <ConfirmModal
                title={t('resetModal.title')}
                labelConfirm={t('resetModal.confirmButton')}
                labelCancel={t('resetModal.cancelButton')}
                onConfirm={() => {
                    setResetModalVisible(false);
                    return handlers?.onReset?.();
                }}
                onCancel={() => setResetModalVisible(false)}
                visible={resetModalVisible}
                danger
            >
                <p>{t('resetModal.message')}</p>
            </ConfirmModal>
        </>
    );
};

export const AssistanceHeaderPagination = () => {
    const { t } = useTranslation('assistance');
    const { documentConfiguration, pagination, record } = useAssistanceContext();
    const { filteredCount, position, nextRecordId, previousRecordId } = pagination || {};

    const initialLoading = !record;

    return (
        <div className="flex gap-2 items-center select-none">
            <AssistanceHeaderIconLink
                disabled={initialLoading || position === 1}
                to={
                    previousRecordId
                        ? url(
                              documentConfiguration.ASSISTANCE_PATH,
                              { recordId: previousRecordId },
                              { keepSearch: true }
                          )
                        : ''
                }
            >
                <ChevronLeftIcon />
            </AssistanceHeaderIconLink>

            <div className="flex gap-1 text-sm text-primary cursor-default min-w-[38px] text-center justify-center tabular-nums">
                {initialLoading ? (
                    <LoadingIcon className="text-brand text-sm animate-spin" />
                ) : (
                    <>
                        <span>{position}</span>
                        <span>{t('header.pagination.of')}</span>
                        <span>{filteredCount}</span>
                    </>
                )}
            </div>

            <AssistanceHeaderIconLink
                disabled={initialLoading || position === filteredCount}
                to={
                    nextRecordId
                        ? url(documentConfiguration.ASSISTANCE_PATH, { recordId: nextRecordId }, { keepSearch: true })
                        : ''
                }
            >
                <ChevronRightIcon />
            </AssistanceHeaderIconLink>
        </div>
    );
};

export const AssistanceAlertBar = () => {
    const { alert } = useAssistanceContext();

    if (!alert) return null;

    return (
        <div
            className={classnames(
                'w-full px-7 py-4 text-sm flex justify-between items-center gap-4 bg-secondary border-b border-solid border-primary',
                alert?.severity == 'success' && 'bg-success text-success',
                alert?.severity == 'warning' && 'bg-warning text-warning',
                alert?.severity == 'error' && 'bg-error text-error'
            )}
        >
            <div className="flex-auto max-w-[1100px]">
                <span className="font-medium inline-block mr-2">{alert?.title}</span>
                <span>{alert?.message}</span>
            </div>
            <span className="flex-none">{alert?.button}</span>
        </div>
    );
};

const AssistanceHeader = () => {
    return (
        <>
            <div className="flex items-center px-4 py-2 gap-4 border-b border-solid border-primary">
                <div className="flex justify-between items-center flex-1 gap-2 min-w-0">
                    <AssistanceHeaderTitleGroup />
                    <AssistanceHeaderViewsToggle />
                </div>

                <div className="h-6 border-r border-solid border-primary" />

                <AssistanceHeaderControls />

                <div className="h-6 border-r border-solid border-primary" />

                <AssistanceHeaderPagination />
            </div>

            <AssistanceAlertBar />
        </>
    );
};

export default React.memo(AssistanceHeader);

export const useErrorAlert = (title, message, secondsVisible = 5) => {
    const [alert, setAlert] = useState(undefined);

    useEffect(() => {
        if (!message) return;

        setAlert({ title, severity: 'error', secondsVisible });

        if (!secondsVisible) return;

        const timeout = setTimeout(() => setAlert(undefined), secondsVisible * 1000);
        return () => clearTimeout(timeout);
    }, [message]);

    return alert;
};

export const useAlert = (record, stepRun, locked, lockedBy) => {
    const { t } = useTranslation('assistance');

    const isSucceeded = stepRun?.executionStatus === 'SUCCEEDED';
    const isFailed = stepRun?.executionStatus === 'FAILED';
    const isPending = stepRun?.executionStatus === 'PENDING';
    const isDiscarded = record?.isDiscarded;
    const isDeleted = record?.deletedAt;
    const isManualOnly = stepRun?.manualOnly;
    const isOnHold = record?.status === 'ON_HOLD';
    const isRejected = record?.status === 'REJECTED';
    const isDeliveryFailed = record?.deliveryRecord?.deliveryStatus === 'FAILED';

    const externalReference = record?.deliveryRecord?.externalReference;
    const externalMessage = record?.deliveryRecord?.externalMessage;
    const externalStatus = record?.deliveryRecord?.externalStatus;
    const externalParts = [
        externalStatus && `${t('header.alerts.deliveryFailed.externalStatus')}: ${externalStatus}`,
        externalMessage && `${t('header.alerts.deliveryFailed.externalMessage')}: ${externalMessage}`,
        externalReference && `${t('header.alerts.deliveryFailed.externalReference')}: ${externalReference}`,
    ].filter((part) => !!part);

    // parse stepRun.context json safely to avoid errors
    let context = stepRun?.context;
    try {
        context = JSON.parse(stepRun?.context);
    } catch (e) {
        context = {};
    }
    const stepAlert = context?.alert;

    const { steprunError } = useSteprunInfo(record, stepRun);

    if (!record || !stepRun) return undefined;

    if (isDeleted) {
        // Final exclusive state
        return {
            title: t('header.alerts.deleted.title', {
                firstName: record?.deletedBy?.firstName,
                lastName: record?.deletedBy?.lastName,
                deletedAt: moment(record?.deletedAt).format('dddd, Do MMMM YYYY, HH:mm'),
                email: record?.deletedBy?.email,
            }),
            severity: 'error',
        };
    } else if (isDiscarded) {
        // Final exclusive state
        return {
            title: t('assistance:discardInfo.title'),
            message: t('assistance:discardInfo.text', {
                date: new Date(record.discardRecord?.createdAt).toLocaleString(),
                user: record.discardRecord?.user
                    ? `${record.discardRecord.user.firstName} ${record.discardRecord.user.lastName}`
                    : 'Workist',
                reason: record.discardRecord?.reason,
            }),
            severity: 'info',
        };
    } else if (isDeliveryFailed) {
        return {
            title: t('header.alerts.deliveryFailed.title'),
            message: externalParts.join(' | '),
            severity: 'error',
            resolution: ERROR_RESOLUTIONS.Assistance,
            assistanceReason: externalMessage,
        };
    } else if ((isFailed || isRejected) && !isPending) {
        // if it is pending, we don't want to show the error message
        if (steprunError != null) {
            return {
                title: steprunError.title,
                message: steprunError.message,
                resolution: steprunError.resolution,
                severity: 'error',
            };
        }

        return {
            title: t('header.alerts.executionFailed.title'),
            message: t('header.alerts.executionFailed.message'),
            severity: 'error',
        };
    } else if (isSucceeded) {
        // Final exclusive state
        let message = '';
        if (record.assistedBy) {
            message = t('header.alerts.executionFinished.messageAssistance', {
                firstName: record.assistedBy.firstName,
                lastName: record.assistedBy.lastName,
                assistedAt: moment(record.assistedAt).format('dddd, Do MMMM YYYY, HH:mm'),
                email: record.assistedBy.email,
            });
        } else if (record.assistedAt) {
            message = t('header.alerts.executionFinished.messageAssistanceDeletedUser', {
                assistedAt: moment(record.assistedAt).format('dddd, Do MMMM YYYY, HH:mm'),
            });
        } else {
            message = t('header.alerts.executionFinished.messageAutomatic');
        }

        return {
            title: t('header.alerts.executionFinished.title'),
            message: message,
            severity: 'success',
        };
    } else if (!isManualOnly) {
        // Intermediate state
        return {
            title: t('header.alerts.noAssistanceNeeded.title'),
            message: t('header.alerts.noAssistanceNeeded.message'),
            severity: 'warning',
        };
    } else if (locked) {
        // Intermediate state
        return {
            title: t('header.alerts.locked.title'),
            message: lockedBy
                ? t('header.alerts.locked.messageWithUser', { email: lockedBy.email })
                : t('header.alerts.locked.message'),
            severity: 'warning',
        };
    } else if (isOnHold) {
        return {
            title: t('header.alerts.onHold.title'),
            message: t('header.alerts.onHold.message', {
                user: `${record.putOnHoldBy.firstName} ${record.putOnHoldBy.lastName}`,
                reason: record.putOnHoldReason || t('header.alerts.onHold.noReason'),
            }),
            severity: 'info',
        };
    } else if (stepAlert) {
        return {
            title: t(`header.alerts.${stepAlert?.name}.title`),
            message: t(`header.alerts.${stepAlert?.name}.message`, stepAlert),
            severity: stepAlert.severity || 'info',
        };
    }
};

const OnHoldModal = (props) => {
    const { onConfirm, onCancel, ...modalProps } = props;
    const { t } = useTranslation('assistance');

    const [reason, setReason] = useState('');

    return (
        <ConfirmModal
            title={t('onHoldModal.reasonLabel')}
            onConfirm={() => onConfirm(reason)}
            onCancel={onCancel}
            labelCancel={t('onHoldModal.cancel')}
            labelConfirm={t('onHoldModal.discardButton')}
            {...modalProps}
        >
            <TextArea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder={t('onHoldModal.reasonPlaceHolder')}
            />
        </ConfirmModal>
    );
};
