import { default as React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateField, StringField, useForm } from '../Fields';
import ProgressButton from '../../../core/containers/ProgressButton';
import { SecondaryButton } from '../../../core/components/Button';
import ConfigurationCard from '../ConfigurationCard';
import ListField from '../ListField';
import moment from 'moment/moment';
import { MasterdataFileUpload } from '../../../masterdata/containers/MasterdataFileUpload';
import ConfirmModal from '../../../core/containers/ConfirmModal';

const MasterdataMappingResetModal = (props) => {
    const { className, children, onConfirm, onCancel, ...modalProps } = props;
    const [date, setDate] = useState('');
    const { t } = useTranslation('config');

    const handleSubmit = () => onConfirm(moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD'));

    return (
        <ConfirmModal
            onConfirm={handleSubmit}
            onCancel={onCancel}
            buttons={
                <>
                    <SecondaryButton label={t('masterDataConfig.resetModal.cancel')} onClick={() => onCancel()} />
                    <ProgressButton
                        label={t('masterDataConfig.resetModal.confirm')}
                        onClick={handleSubmit}
                        disabled={!date}
                    />
                </>
            }
            {...modalProps}
        >
            <div className="form">
                <p>{t('masterDataConfig.resetModal.description')}</p>
                <DateField
                    label={t('masterDataConfig.resetModal.dateLabel')}
                    inputProps={{ placeholder: t('masterDataConfig.resetModal.datePlaceholder') }}
                    className="field--vertical"
                    value={date}
                    setValue={setDate}
                />
            </div>
        </ConfirmModal>
    );
};

const MasterDataConfiguration = ({
    user,
    config,
    onActivate,
    onDeactivate = undefined,
    onSubmit,
    channelId,
    service,
    onDeleteMappings = undefined,
}) => {
    const { getFieldProps, formData, setFormData } = useForm({
        getLabel: (fieldName) => t(`masterDataConfig.fieldNames.${fieldName}`),
    });
    const { t } = useTranslation('config');
    useEffect(() => {
        setFormData({
            authToken: config?.authToken || '',

            clientLookupDefinitionId: config?.clientLookupDefinitionId || '',
            receiverLookupDefinitionId: config?.receiverLookupDefinitionId || '',
            contactLookupDefinitionId: config?.contactLookupDefinitionId || '',
            emailLookupDefinitionId: config?.emailLookupDefinitionId || '',

            articleLookupDefinitionId: config?.articleLookupDefinitionId || '',
            debitorArticleLookupDefinitionId: config?.debitorArticleLookupDefinitionId || '',
            conversionFactorLookupDefinitionId: config?.conversionFactorLookupDefinitionId || '',

            invoiceAddressLookupDefinitionId: config?.invoiceAddressLookupDefinitionId || '',
            deliveryAddressLookupDefinitionId: config?.deliveryAddressLookupDefinitionId || '',
            clientMappingLookupDefinitionId: config?.clientMappingLookupDefinitionId || '',

            articleMappingLookupDefinitionId: config?.articleMappingLookupDefinitionId || '',
            vatIdCheckMappingLookupDefinitionId: config?.vatIdCheckMappingLookupDefinitionId || '',

            invoiceAddressMappingLookupDefinitionId: config?.invoiceAddressMappingLookupDefinitionId || '',
            deliveryAddressMappingLookupDefinitionId: config?.deliveryAddressMappingLookupDefinitionId || '',

            orderMatchingLookupDefinitionId: config?.orderMatchingLookupDefinitionId || '',
        });
    }, [config]);

    const handleSubmit = () => onSubmit(formData);

    const [deleteClientMappingsModalVisible, setDeleteClientMappingsModalVisible] = useState(false);
    const [deleteArticleMappingsModalVisible, setDeleteArticleMappingsModalVisible] = useState(false);
    const [deleteDeliveryAddressMappingsModalVisible, setDeleteDeliveryAddressMappingsModalVisible] = useState(false);
    const [deleteInvoiceAddressMappingsModalVisible, setDeleteInvoiceAddressMappingsModalVisible] = useState(false);

    const handleDeleteClientMappings = (date) => {
        return onDeleteMappings?.(date, 'ADDRESS')
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setDeleteClientMappingsModalVisible(false);
            });
    };

    const handleDeleteInvoiceAddressMappings = (date) => {
        return onDeleteMappings?.(date, 'INVOICE_ADDRESS')
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setDeleteInvoiceAddressMappingsModalVisible(false);
            });
    };

    const handleDeleteDeliveryAddressMappings = (date) => {
        return onDeleteMappings?.(date, 'DELIVERY_ADDRESS')
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setDeleteDeliveryAddressMappingsModalVisible(false);
            });
    };

    const handleDeleteArticleMappings = (date) => {
        return onDeleteMappings?.(date, 'ARTICLE')
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setDeleteArticleMappingsModalVisible(false);
            });
    };

    return (
        <ConfigurationCard
            title={t('masterDataConfig.title')}
            description={t('masterDataConfig.description')}
            active={!!config}
            onActivate={onActivate}
            onDeactivate={onDeactivate}
        >
            <div className="form">
                <StringField {...getFieldProps('authToken')} className="field--vertical" />

                <StringField {...getFieldProps('clientLookupDefinitionId')} className="field--vertical" />
                <StringField {...getFieldProps('receiverLookupDefinitionId')} className="field--vertical" />
                <StringField {...getFieldProps('contactLookupDefinitionId')} className="field--vertical" />
                <StringField {...getFieldProps('emailLookupDefinitionId')} className="field--vertical" />

                <StringField {...getFieldProps('articleLookupDefinitionId')} className="field--vertical" />
                <StringField {...getFieldProps('debitorArticleLookupDefinitionId')} className="field--vertical" />
                <StringField {...getFieldProps('conversionFactorLookupDefinitionId')} className="field--vertical" />

                <StringField {...getFieldProps('invoiceAddressLookupDefinitionId')} className="field--vertical" />
                <StringField {...getFieldProps('deliveryAddressLookupDefinitionId')} className="field--vertical" />

                <StringField {...getFieldProps('orderMatchingLookupDefinitionId')} className="field--vertical" />

                <div className="form__field-group form__field-group--horizontal">
                    <StringField {...getFieldProps('clientMappingLookupDefinitionId')} className="field--vertical" />
                    {onDeleteMappings && (
                        <SecondaryButton
                            label={t('masterDataConfig.resetButton')}
                            onClick={() => {
                                setDeleteClientMappingsModalVisible(true);
                            }}
                        />
                    )}
                    {deleteClientMappingsModalVisible && (
                        <MasterdataMappingResetModal
                            onConfirm={handleDeleteClientMappings}
                            onCancel={() => {
                                setDeleteClientMappingsModalVisible(false);
                            }}
                            visible={true}
                        />
                    )}
                </div>

                <div className="form__field-group form__field-group--horizontal">
                    <StringField {...getFieldProps('articleMappingLookupDefinitionId')} className="field--vertical" />
                    {onDeleteMappings && (
                        <SecondaryButton
                            label={t('masterDataConfig.resetButton')}
                            onClick={() => {
                                setDeleteArticleMappingsModalVisible(true);
                            }}
                        />
                    )}
                    {deleteArticleMappingsModalVisible && (
                        <MasterdataMappingResetModal
                            onConfirm={handleDeleteArticleMappings}
                            onCancel={() => {
                                setDeleteArticleMappingsModalVisible(false);
                            }}
                            visible={true}
                        />
                    )}
                </div>

                <div className="form__field-group form__field-group--horizontal">
                    <StringField
                        {...getFieldProps('deliveryAddressMappingLookupDefinitionId')}
                        className="field--vertical"
                    />
                    {onDeleteMappings && (
                        <SecondaryButton
                            label={t('masterDataConfig.resetButton')}
                            onClick={() => {
                                setDeleteDeliveryAddressMappingsModalVisible(true);
                            }}
                        />
                    )}
                    {deleteDeliveryAddressMappingsModalVisible && (
                        <MasterdataMappingResetModal
                            onConfirm={handleDeleteDeliveryAddressMappings}
                            onCancel={() => {
                                setDeleteDeliveryAddressMappingsModalVisible(false);
                            }}
                            visible={true}
                        />
                    )}
                </div>

                <div className="form__field-group form__field-group--horizontal">
                    <StringField
                        {...getFieldProps('invoiceAddressMappingLookupDefinitionId')}
                        className="field--vertical"
                    />
                    {onDeleteMappings && (
                        <SecondaryButton
                            label={t('masterDataConfig.resetButton')}
                            onClick={() => {
                                setDeleteInvoiceAddressMappingsModalVisible(true);
                            }}
                        />
                    )}
                    {deleteInvoiceAddressMappingsModalVisible && (
                        <MasterdataMappingResetModal
                            onConfirm={handleDeleteInvoiceAddressMappings}
                            onCancel={() => {
                                setDeleteInvoiceAddressMappingsModalVisible(false);
                            }}
                            visible={true}
                        />
                    )}
                </div>

                <StringField {...getFieldProps('vatIdCheckMappingLookupDefinitionId')} className="field--vertical" />

                <div>
                    <ProgressButton label={t('card.save')} onClick={handleSubmit} />
                </div>
            </div>
            <MasterdataFileUpload config={config} channelId={channelId} user={user} />
        </ConfigurationCard>
    );
};

export default MasterDataConfiguration;
