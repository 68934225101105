import { gql } from '@apollo/client';
import { FULL_CHANNEL } from './pages/Configuration/queries';
import { BASE_DOCUMENT, BASE_FIELD, PRIMITIVE_FIELD, SELECT_FIELD_FRAGMENT } from '../assistance/queries';

const FULL_INVOICE = gql`
    ${SELECT_FIELD_FRAGMENT}
    
    fragment FullInvoice on Invoice {
        ${BASE_DOCUMENT}
        
        invoiceNumber {
            ${PRIMITIVE_FIELD}
        }

        orderNumber {
            ${PRIMITIVE_FIELD}
        }

        contractNumber {
            ${PRIMITIVE_FIELD}
        }

        deliveryNoteNumber {
            ${PRIMITIVE_FIELD}
        }

        invoiceDate {
            ${PRIMITIVE_FIELD}
        }

        invoiceDueDate {
            ${PRIMITIVE_FIELD}
        }

        deliveryDate {
            ${PRIMITIVE_FIELD}
        }

        recipientCustomerNumber {
            ${PRIMITIVE_FIELD}
        }

        recipientAddress {
            addressId
            name
            postcode
            city
            countrySubdivision
            country
            phone
            email
            companyName
            streetAndNr
            ${BASE_FIELD}
        }

        recipientContact {
            name
            contactId
            phone
            email
            ${BASE_FIELD}
        }

        senderSupplier {
            name
            customerNumber
            customerType
            misc
            contactPerson
            ${BASE_FIELD}

            address {
                addressId
                name
                postcode
                city
                country
                phone
                email
                companyName
                streetAndNr
                misc
                bbox
                pageIndex
                predictionConfidence
            }
        }

        senderVatId {
            ${PRIMITIVE_FIELD}
        }
        
        senderTaxNumber {
            ${PRIMITIVE_FIELD}
        }

        senderAddress {
            addressId
            name
            postcode
            city
            countrySubdivision
            country
            phone
            email
            companyName
            streetAndNr
            ${BASE_FIELD}
        }

        senderContact {
            name
            bbox
            pageIndex
            contactId
            phone
            email
            ${BASE_FIELD}
        }

        paymentBank {
            ${PRIMITIVE_FIELD}
        }

        paymentBic {
            ${PRIMITIVE_FIELD}
        }

        paymentIban {
            ${PRIMITIVE_FIELD}
        }

        paymentMethod {
            ${PRIMITIVE_FIELD}
        }

        paymentReference {
            ${PRIMITIVE_FIELD}
        }

        paymentSwift {
            ${PRIMITIVE_FIELD}
        }

        paymentTermsDiscount {
            ${PRIMITIVE_FIELD}
        }

        paymentTermsDueDays {
            ${PRIMITIVE_FIELD}
        }

        paymentTermsType {
            ${PRIMITIVE_FIELD}
        }
        
        paymentTerms {
            ${PRIMITIVE_FIELD}
        }

        currency {
            ...FullSelectField
        }

        totalAmount {
            ${PRIMITIVE_FIELD}
        }

        totalDue {
            ${PRIMITIVE_FIELD}
        }

        taxRate {
            ${PRIMITIVE_FIELD}
        }

        taxAmount {
            ${PRIMITIVE_FIELD}
        }

        documentType {
            ${PRIMITIVE_FIELD}
        }

        netAmount {
            ${PRIMITIVE_FIELD}
        }

        lineItems {
            isMatchingIgnored
            ${BASE_FIELD}

            pos {
                ${PRIMITIVE_FIELD}
            }
            articleNumber {
                ${PRIMITIVE_FIELD}
            }
            articleNumber2 {
                ${PRIMITIVE_FIELD}
            }
            articleNumber3 {
                ${PRIMITIVE_FIELD}
            }
            articlePartition {
                ${PRIMITIVE_FIELD}
            }
            articlePartitionSpecificNumber {
                ${PRIMITIVE_FIELD}
            }
            fulfillmentDate {
                ${PRIMITIVE_FIELD}
            }
            articleDescription {
                ${PRIMITIVE_FIELD}
            }
            articleDescriptionMasterData {
                ${PRIMITIVE_FIELD}
            }
            grossPrice {
                ${PRIMITIVE_FIELD}
            }
            unitPrice {
                ${PRIMITIVE_FIELD}
            }
            totalPrice {
                ${PRIMITIVE_FIELD}
            }
            unit {
                ...FullSelectField
            }
            quantity {
                ${PRIMITIVE_FIELD}
            }
            taxRate {
                ${PRIMITIVE_FIELD}
            }
            taxAmount {
                ${PRIMITIVE_FIELD}
            }
            orderNumber {
                ${PRIMITIVE_FIELD}
            }
            dynamicSchemaSerialized
        }
        dynamicSchemaSerialized
    }
`;

const FULL_RECORD = gql`
    ${FULL_INVOICE}
    ${FULL_CHANNEL}
    fragment FullRecord on InvoiceProcessingRecord {
        id
        createdAt
        deletedAt
        deletedBy {
            email
            firstName
            lastName
        }

        sender
        subject

        status

        canFinishAssistance
        canFinishHeaderAssistance
        canFinishLineItemsAssistance

        lockedBy {
            email
        }

        putOnHoldReason
        putOnHoldBy {
            email
            firstName
            lastName
        }

        assistedBy {
            email
            firstName
            lastName
        }

        assistedAt

        stepRun {
            id
            stepName
            manualOnly
            executionStatus
            error
            context
        }

        invoiceprocessingsteprunSet {
            id
            createdAt
            updatedAt
            stepName
            manualOnly
            executionStatus
            context
            error
        }

        channel {
            ...FullChannel
        }

        isDiscarded
        isTestDocument

        discardRecord {
            reason
            user {
                id
                firstName
                lastName
            }
            createdAt
            updatedAt
        }

        deliveryRecord {
            deliveryMethod
            deliveryStatus
            externalReference
            externalMessage
            externalStatus
        }

        sourceFile {
            ... on Email {
                sender {
                    email
                    name
                }
                receivers {
                    email
                    name
                }
                subject
                messageId
                language
                receivedAt

                text
                html
                attachments {
                    cid
                    contentDisposition
                    name
                    type
                    url
                }

                senderClientId
                prediction
                predictionConfidence
                sourceUrl

                predictionConfidences
            }

            ... on File {
                name
                type
                url
            }
        }

        outputFile {
            ... on Export {
                files {
                    name
                    url
                }
            }
        }

        invoiceUnsaved {
            ...FullInvoice
        }

        labelingStatus

        masterdataVersionsExtracted
        isMatchingEnabled
    }
`;

export const GET_OVERVIEW_DATA = gql`
    query {
        openInvoiceProcessingRecordsCount
        invoiceProcessingChannels {
            id
            name
        }
    }
`;

export const GET_TABLE_OVERVIEW_DATA = gql`
    query ($filters: [TableFilter], $offset: Int, $first: Int, $channelId: UUID) {
        openInvoiceProcessingRecordsCount
        filteredInvoiceProcessingRecords(filters: $filters, offset: $offset, first: $first) {
            totalCount
            edges {
                node {
                    id
                    sender
                    subject
                    status
                    createdAt
                    finishedAt
                    assistedAt
                    assistedBy {
                        id
                        firstName
                        lastName
                        email
                    }
                    deletedAt
                    deletedBy {
                        id
                        firstName
                        lastName
                        email
                    }
                    stepRun {
                        id
                        manualOnly
                        executionStatus
                        error
                        context
                    }
                    channel {
                        id
                        name

                        extractorConfig {
                            validationChecks
                        }
                    }
                    isDiscarded
                    deliveryRecord {
                        deliveryMethod
                        deliveryStatus
                        externalReference
                        externalMessage
                        externalStatus
                    }
                    discardRecord {
                        reason
                        user {
                            id
                            firstName
                            lastName
                            email
                        }
                        createdAt
                        updatedAt
                    }
                    invoiceUnsaved {
                        validationChecks {
                            name
                            result
                            targetValue
                            reference
                        }

                        orderNumber {
                            value
                            predictionConfidence
                            confidenceExplanation {
                                translationKey
                                explanationDetails
                            }
                        }
                    }
                    isTestDocument
                    canProcessAutomatically
                }
            }
        }
        openInvoiceProcessingRecordsPerChannelCount(channelId: $channelId)
        invoiceProcessingChannels {
            id
            name
            emailAddress
            team {
                id
                users {
                    email
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const GET_ASSISTANCE_OVERVIEW_DATA = gql`
    query ($recordId: UUID!, $filters: [TableFilter]) {
        invoiceProcessingRecordAssistancePagination(recordId: $recordId, filters: $filters) {
            filteredCount
            position
            previousRecordId
            nextRecordId
        }
    }
`;

export const GET_ASSISTANCE_DATA = gql`
    ${FULL_RECORD}
    ${FULL_INVOICE}
    query ($id: UUID!) {
        invoiceProcessingRecord(id: $id) {
            ...FullRecord

            invoiceExtracted {
                ...FullInvoice
            }

            ocr {
                pagesCount
                processedFile {
                    url
                }
            }
        }
    }
`;

export const UPDATE_PROCESSING_RECORD = gql`
    mutation ($recordId: UUID!, $fieldName: String, $itemIndex: Int, $action: String!, $payload: GenericScalar) {
        invoiceUpdate(
            recordId: $recordId
            fieldName: $fieldName
            itemIndex: $itemIndex
            action: $action
            payload: $payload
        ) {
            record {
                canFinishAssistance
                canFinishHeaderAssistance
                canFinishLineItemsAssistance
            }

            patch {
                op
                path
                from
                value
            }
        }
    }
`;

export const RESELECT_PROCESSING_RECORD = gql`
    mutation ($recordId: UUID!, $fieldName: String, $bbox: [[Float]]!, $pageIndex: Int!, $itemIndex: Int) {
        invoiceReselect(
            recordId: $recordId
            fieldName: $fieldName
            bbox: $bbox
            pageIndex: $pageIndex
            itemIndex: $itemIndex
        ) {
            record {
                canFinishAssistance
                canFinishHeaderAssistance
                canFinishLineItemsAssistance
            }

            patch {
                op
                path
                from
                value
            }
        }
    }
`;

export const DELETE_INVOICE = gql`
    mutation ($recordId: UUID!) {
        invoiceDelete(recordId: $recordId) {
            success
        }
    }
`;

export const RETRY_STEP = gql`
    mutation ($recordId: UUID!, $stepRunId: UUID!) {
        invoiceRetryStep(recordId: $recordId, stepRunId: $stepRunId) {
            success
        }
    }
`;

export const DISCARD_INVOICE = gql`
    mutation ($recordId: UUID!, $reason: String) {
        invoiceDiscard(recordId: $recordId, reason: $reason) {
            success
        }
    }
`;

export const REOPEN_FOR_ASSISTANCE = gql`
    mutation ($recordId: UUID!, $reason: String) {
        invoiceReopenForAssistance(recordId: $recordId, reason: $reason) {
            success
        }
    }
`;

export const UPLOAD_FILE = gql`
    mutation ($channelId: UUID!, $files: [Upload]!, $batchId: String, $isTestingDocument: Boolean!) {
        invoiceUploadFile(
            input: { channelId: $channelId, files: $files, batchId: $batchId, isTestingDocument: $isTestingDocument }
        ) {
            records {
                id
            }
            success
        }
    }
`;

export const RE_UPLOAD_FILE = gql`
    mutation ($channelId: UUID!, $files: [Upload]!, $recordId: String!, $isTestingDocument: Boolean!) {
        invoiceUploadFile(
            input: { channelId: $channelId, files: $files, recordId: $recordId, isTestingDocument: $isTestingDocument }
        ) {
            records {
                id
            }
            success
        }
    }
`;

export const SEND_TO_LABELING = gql`
    mutation ($recordId: UUID!) {
        invoiceSendToLabeling(recordId: $recordId) {
            success
        }
    }
`;

export const GET_NEXT_ASSISTANCE_RECORD = gql`
    query ($filters: [TableFilter], $channelId: UUID) {
        nextInvoiceProcessingRecord(filters: $filters, channelId: $channelId) {
            id
        }
    }
`;

export const CREATE_CHANNEL = gql`
    mutation ($name: String!, $fromExistingChannelId: UUID) {
        createInvoiceProcessingChannel(name: $name, fromExistingChannelId: $fromExistingChannelId) {
            channel {
                id
            }
            success
        }
    }
`;

export const GET_CUSTOMER_AUTOMATION_RATIO = gql`
    query ($startDate: Date!, $endDate: Date!) {
        customerInvoiceAutomationRatio(startDate: $startDate, endDate: $endDate) {
            automationDays {
                date
                assistedCount
                automaticCount
                totalCount
                automationRatio
            }
            last5DaysAutomationRatio
        }
    }
`;

export const GET_CHANNEL_AUTOMATION_RATIO = gql`
    query ($channelId: UUID!, $startDate: Date!, $endDate: Date!) {
        channelInvoiceAutomationRatio(channelId: $channelId, startDate: $startDate, endDate: $endDate) {
            automationDays {
                date
                assistedCount
                automaticCount
                totalCount
                automationRatio
            }
            last5DaysAutomationRatio
        }
    }
`;

export const GET_ALL_CHANNELS = gql`
    ${FULL_CHANNEL}
    query {
        invoiceProcessingChannels {
            ...FullChannel
        }
    }
`;

export const INVOICE_EXTRACT_TEXT = gql`
    mutation InvoiceExtractText($recordId: UUID!, $pageIndex: Int!, $bbox: [[Float]]!) {
        invoiceExtractText(recordId: $recordId, pageIndex: $pageIndex, bbox: $bbox) {
            bbox
            text
        }
    }
`;
