import { faBarsFilter } from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    AssistanceAlertBar,
    AssistanceHeaderControls,
    AssistanceHeaderPagination,
    AssistanceHeaderTitleGroup,
} from '../../assistance/containers/AssistanceHeader';
import Tooltip from '../../core/components/Tooltip';
import { withIcon } from '../../core_updated/components/Icon';
import { useAssistanceContext } from '../../generic_document/pages/Assistance/AssistanceContext';
import Button from '../../core/components/Button';
import { default as FilterButton } from '../../core_updated/components/Button';

const FilterIcon = withIcon(faBarsFilter);

const ListOfServicesAssistanceHeaderButtons = ({
    filterListOfServicesPositions,
    onFilterListOfServicesPositionsChange,
}) => {
    const { record, loading, handlers, readOnly } = useAssistanceContext();

    const { t } = useTranslation('assistance');

    const numberOfRelevantPositions: number =
        record?.listOfServicesUnsaved?.positions?.filter(
            (item) => item.prediction === 'Position' && item.isRelevant.value === 'True'
        ).length || 0;

    const numberPositions: number =
        record?.listOfServicesUnsaved?.positions?.filter((item) => item.prediction === 'Position').length || 0;

    return (
        <div className="flex gap-2 select-none flex-none">
            <FilterButton
                onClick={() => {
                    onFilterListOfServicesPositionsChange(!filterListOfServicesPositions);
                }}
                active={filterListOfServicesPositions}
                className={classnames('flex flex-none gap-2 items-center')}
            >
                <FilterIcon className="fa-lg" />{' '}
                {numberOfRelevantPositions +
                    ' ' +
                    t('listOfServicesView.relevantPositions') +
                    ' ' +
                    t('header.pagination.of') +
                    ' ' +
                    numberPositions}
            </FilterButton>

            {!record?.canFinishAssistance ? (
                <Tooltip
                    content={!record?.canFinishAssistance && t('finishButton.finishDisabledNote')}
                    placement="top"
                    long
                    className="tooltip--flex"
                >
                    <Button
                        label={t('finishButton.finishDocument')}
                        disabled={loading || !record?.canFinishAssistance || readOnly}
                    />
                </Tooltip>
            ) : (
                <Button
                    label={t('finishButton.finishDocument')}
                    disabled={loading || !record?.canFinishAssistance || readOnly}
                    onClick={handlers.onFinish}
                />
            )}
        </div>
    );
};

const ListOfServicesAssistanceHeader = ({
    props,
    filterListOfServicesPositions,
    onFilterListOfServicesPositionsChange,
}) => {
    return (
        <>
            <div className="flex items-center px-4 py-2 gap-4 border-b border-solid border-primary">
                <div className="flex justify-between items-center flex-1 gap-2 min-w-0">
                    <AssistanceHeaderTitleGroup />
                    <ListOfServicesAssistanceHeaderButtons
                        filterListOfServicesPositions={filterListOfServicesPositions}
                        onFilterListOfServicesPositionsChange={onFilterListOfServicesPositionsChange}
                    />
                </div>

                <div className="h-6 border-r border-solid border-primary" />

                <AssistanceHeaderControls />

                <div className="h-6 border-r border-solid border-primary" />

                <AssistanceHeaderPagination />
            </div>

            <AssistanceAlertBar />
        </>
    );
};

export default ListOfServicesAssistanceHeader;
