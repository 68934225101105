import React, { useContext, useEffect, useState } from 'react';
import AutoCompleteField from '../../core_updated/components/Fields/AutoCompleteField';
import TextField from '../../core_updated/components/Fields/TextField';
import { useAssistanceFieldContext } from '../pages/Assistance/AssistanceFieldContext';
import StringField from '../../core_updated/components/Fields/StringField';
import { useTranslation } from 'react-i18next';
import useConfirmValueUpdate from './useConfirmValueUpdate';
import ConfirmModal from '../../core/containers/ConfirmModal';
import { renderClientOption } from './useLookup';
import AssistanceSection from '../pages/Assistance/AssistanceSection';
import {
    IHeaderDataFieldGroupProps,
    useHeaderDataFieldGroupProps,
} from '../pages/Assistance/customizable/HeaderDataFieldGroup';
import { FieldProps, useFieldProps } from '../pages/Assistance/customizable/Field';
import { IConditionalComponent } from '../../customizations/ConditionalComponentContext';
import useForceSelectionLookupProps, { useForceSelectionReselectEventListener } from './useForceSelectionLookupProps';
import { HeaderDataFieldGroupControls } from '../pages/Assistance/HeaderDataSection';
import { useAssistanceViewContext } from '../pages/Assistance/AssistanceViewContext';

const joinAddress = (address) => {
    return (
        [
            address.name || '',
            address.companyName || '',
            address.streetAndNr || '',
            address.misc || '',
            `${address.postcode || ''} ${address.city || ''}`.trim(),
            address.country || '',
        ]
            .filter((i) => i)
            .join('\n') || ''
    );
};

interface IConfirmValueOptions {
    onConfirm: () => void;
    onCancel: () => void;
    waitingForConfirm: boolean;
}

const ConfirmValueContext = React.createContext<{
    confirmValueOptions: IConfirmValueOptions;
    onConfirmValueOptionsChange: (options: IConfirmValueOptions) => void;
}>({
    confirmValueOptions: {
        onConfirm: () => {},
        onCancel: () => {},
        waitingForConfirm: false,
    },
    onConfirmValueOptionsChange: (options: IConfirmValueOptions) => {},
});

export const CustomerFieldGroup = ({ children, fieldName, fields, controls, ...props }: IHeaderDataFieldGroupProps) => {
    const { t } = useTranslation('assistance');

    const [confirmValueOptions, setConfirmValueOptions] = useState({
        onConfirm: () => {},
        onCancel: () => {},
        waitingForConfirm: false,
    });

    const { onFieldReselect } = useAssistanceViewContext();

    const resetConfirmValueOptions = () => {
        setConfirmValueOptions({
            onConfirm: () => {},
            onCancel: () => {},
            waitingForConfirm: false,
        });
    };

    const handleFieldReselect = ({ fieldName, bbox, pageIndex }) => {
        setConfirmValueOptions({
            onConfirm: () => {
                resetConfirmValueOptions();
                return onFieldReselect({ fieldName, bbox, pageIndex });
            },
            onCancel: () => {
                resetConfirmValueOptions();
            },
            waitingForConfirm: true,
        });
    };

    const fieldGroupProps = useHeaderDataFieldGroupProps({ fieldName, fields, children, controls });
    const controlsProps = (React.Children.toArray(controls)[0] as any)?.props || {}; // reuse props from slot
    return (
        <ConfirmValueContext.Provider
            value={{ confirmValueOptions, onConfirmValueOptionsChange: setConfirmValueOptions }}
        >
            <AssistanceSection.FieldGroup
                {...fieldGroupProps}
                title={props.title || fieldGroupProps.title}
                controls={
                    <HeaderDataFieldGroupControls {...controlsProps} onFieldReselect={handleFieldReselect} hideClear />
                }
            >
                {children}

                <ConfirmModal
                    onConfirm={confirmValueOptions.onConfirm}
                    onCancel={confirmValueOptions.onCancel}
                    visible={confirmValueOptions.waitingForConfirm}
                >
                    <p>{t('fields.customerField.modal')}</p>
                </ConfirmModal>
            </AssistanceSection.FieldGroup>
        </ConfirmValueContext.Provider>
    );
};

export const CustomerNumberConfirmField = (props: FieldProps) => {
    const { clientPartitionId, config } = useAssistanceFieldContext();
    const { lookupDefinitionId, lookupFieldName, allowInvalidIds } = config;

    const fieldProps = useFieldProps(props);
    const { value, onValueChange, onFocus, onBlur } = fieldProps;

    const { onConfirmValueOptionsChange } = useContext(ConfirmValueContext);
    const confirmValueOptions = useConfirmValueUpdate({
        value,
        onValueChange,
        onUpdatePayloadChange: props.onUpdatePayloadChange,
        onFocus,
        onBlur,
    });

    useEffect(() => {
        onConfirmValueOptionsChange(confirmValueOptions);
    }, [confirmValueOptions.waitingForConfirm]);

    const forceSelectionLookupProps = useForceSelectionLookupProps({
        lookupType: 'ClientLookupType',
        lookupDefinitionId,
        lookupFieldName,
        partitionId: clientPartitionId,
        allowInvalidIds,
        value,
        onValueChange,
        onUpdatePayloadChange: props.onUpdatePayloadChange,
        onFocus: confirmValueOptions.onFocus,
        onBlur: confirmValueOptions.onBlur,
        isPartitionIdField: true,
    });

    useForceSelectionReselectEventListener(props.inputRef, forceSelectionLookupProps.onValueChange);

    return <AutoCompleteField {...fieldProps} {...forceSelectionLookupProps} renderOption={renderClientOption} />;
};

export const CustomerNumberField = (props: FieldProps) => {
    const { clientPartitionId, config } = useAssistanceFieldContext();
    const { lookupDefinitionId, lookupFieldName, allowInvalidIds } = config;

    const fieldProps = useFieldProps(props);
    const { value, onValueChange, onFocus, onBlur } = fieldProps;

    const forceSelectionLookupProps = useForceSelectionLookupProps({
        lookupType: 'ClientLookupType',
        lookupDefinitionId,
        lookupFieldName,
        partitionId: clientPartitionId,
        allowInvalidIds,
        value,
        onValueChange,
        onUpdatePayloadChange: props.onUpdatePayloadChange,
        onFocus,
        onBlur,
        isPartitionIdField: true,
    });

    useForceSelectionReselectEventListener(props.inputRef, forceSelectionLookupProps.onValueChange);

    return <AutoCompleteField {...fieldProps} {...forceSelectionLookupProps} renderOption={renderClientOption} />;
};

const CustomerAddressField = (props: FieldProps) => {
    const { value, ...fieldProps } = useFieldProps(props);

    return <TextField {...fieldProps} readOnly={true} disabled={true} value={value && joinAddress(value)} />;
};

const CustomerInternalNoteField = (props: FieldProps) => {
    const fieldProps = useFieldProps(props);

    return <StringField {...fieldProps} readOnly={true} disabled={true} />;
};

export const getCustomerFieldConditionalComponents = (customerFieldKey: string): IConditionalComponent[] => [
    {
        // line item field
        condition: ({ fieldName, itemIndex }) =>
            itemIndex !== undefined && fieldName === `${customerFieldKey}__customer_number`,
        component: CustomerNumberField,
    },
    {
        // header field
        condition: ({ fieldName, itemIndex }) =>
            itemIndex === undefined && fieldName === `${customerFieldKey}__customer_number`,
        component: CustomerNumberConfirmField,
    },
    {
        condition: ({ fieldName }) => fieldName === `${customerFieldKey}__name`,
        component: CustomerNumberConfirmField, // We can use the same component for both fields
    },
    {
        condition: ({ fieldName }) => fieldName === `${customerFieldKey}__address`,
        component: CustomerAddressField,
    },
    {
        condition: ({ fieldName }) => fieldName === `${customerFieldKey}__internal_note`,
        component: CustomerInternalNoteField,
    },
];

export const getCustomerFieldGroupConditionalComponents = (customerFieldKey: string) => [
    {
        condition: ({ fieldName }) => fieldName === customerFieldKey,
        component: CustomerFieldGroup,
    },
];
