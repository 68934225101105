/**
 * The standard schema for each masterdata file
 **/

export const ARTICLE_LIST_COLUMNS = [
    'article_id1',
    'article_id2',
    'article_id3',
    'description',
    'gross_price',
    'order_units',
];

export const DEBITOR_ARTICLE_LIST_COLUMNS = [
    'partition_id',
    'article_id1',
    'article_id2',
    'article_id3',
    'description',
    'gross_price',
    'order_units',
];

export const CLIENT_LIST_COLUMNS = [
    'client_id1',
    'client_id2',
    'client_type',
    'client_contact_person1',
    'company_name',
    'address1',
    'address2',
    'zip_code',
    'city',
    'po_box',
    'country',
    'domain',
];

export const ADDRESS_LIST_COLUMNS = [
    'partition_id',
    'address_id1',
    'address_id2',
    'address_id3',
    'contact_person',
    'name',
    'address1',
    'address2',
    'zip_code',
    'city',
    'po_box',
    'country',
    'phone',
];

export const CONVERSION_FACTOR_COLUMNS = [
    'partition_id',
    'article_id',
    'source_unit',
    'target_unit',
    'conversion_quotient',
];
export const CONTACT_COLUMNS = ['partition_id', 'contact_id', 'first_name', 'last_name', 'phone', 'email'];
export const ORDER_MATCHING_COLUMNS = ['order_number', 'article_id1'];
