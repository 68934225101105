import { useTranslation } from 'react-i18next';
import { applyPatch } from 'fast-json-patch';
import { useChannelStore, useDocumentStore } from '../../../stores';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { appInsights } from '../../../core/analytics/applicationInsights';
import {
    deleteRecordEvent,
    discardChangesEvent,
    finishAssistanceEvent,
    removeDocumentEvent,
    sendToLabelingEvent,
} from '../../../core/analytics/customEvents';
import { camelCase, merge } from 'lodash';
import { url } from '../../../core/utils/link';
import { useErrorAlert } from '../../../assistance/containers/AssistanceHeader';
import { unpackAndMergeDynamicSchemaFields } from '../../../utils';

const adjustPatch = (patch) => {
    const removeDynamicSchema = (operation) => {
        operation.path = operation.path.replaceAll('/dynamicSchema', '');
        return operation;
    };

    const convertToTypename = (data) => {
        if (typeof data === 'object' && data !== null) {
            if ('__name__' in data) {
                data['__typename'] = data['__name__'];
                delete data['__name__'];
            }
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    convertToTypename(data[key]);
                }
            }
        } else if (Array.isArray(data) && data !== null) {
            data.forEach((elem) => {
                convertToTypename(elem);
            });
        }
    };

    let adjustedPatch = patch.map(removeDynamicSchema);
    convertToTypename(adjustedPatch);

    return adjustedPatch;
};

const useAssistanceHandlers = ({
    user,
    documentConfiguration,
    recordId,
    record,
    setRecord,
    dataRefetch,
    lock,
    cleanLock,
    pagination,
}) => {
    const { t } = useTranslation('assistance');

    const documentStore = useDocumentStore();
    const navigate = useNavigate();

    const [update, { loading: updateLoading, error: updateError }] = documentStore.useUpdateDocument(
        documentConfiguration.documentType
    );
    const [reselect, { loading: reselectLoading, error: reselectError }] = documentStore.useReselectDocument(
        documentConfiguration.documentType
    );
    const [retryStep, { loading: retryStepLoading, error: retryStepError }] = useMutation(
        documentConfiguration.RETRY_STEP
    );

    // DBImmo documentConfigurations have no reopen for assistance mutation
    const [reopenForAssistance, { loading: reopenForAssistanceLoading, error: reopenForAssistanceError }] =
        documentConfiguration.REOPEN_FOR_ASSISTANCE
            ? useMutation(documentConfiguration.REOPEN_FOR_ASSISTANCE)
            : [null, { loading: false, error: new Error('Mutation not available') }];

    const [discard, { loading: discardLoading, error: discardError }] = useMutation(documentConfiguration.DISCARD);

    const [deleteRecord, { loading: deleteRecordLoading, error: deleteRecordError }] = useMutation(
        documentConfiguration.DELETE
    );

    const [sendToLabeling, { loading: sendToLabelingLoading, error: sendToLabelingError }] = useMutation(
        documentConfiguration.SEND_TO_LABELING
    );

    const [discardModalVisible, setDiscardModalVisible] = useState(false);

    const [masterdataBrowserModalVisible, setMasterdataBrowserModalVisible] = useState(false);
    const [masterdataBrowserModalState, setMasterdataBrowserModalState] = useState({
        searchTerm: null,
        targetField: null,
    });
    const openMasterdataBrowser = (triggerState: any) => {
        setMasterdataBrowserModalState(triggerState);
        setMasterdataBrowserModalVisible(true);
    };
    const closeMasterdataBrowser = () => {
        setMasterdataBrowserModalVisible(false);
        setMasterdataBrowserModalState({
            searchTerm: null,
            targetField: null,
        });
    };

    const stepRun = record?.stepRun || {};

    const handleUpdate = useCallback(
        ({
            fieldName = undefined,
            itemIndex = undefined,
            itemType = undefined,
            action = 'update',
            payload = undefined,
            forceRefetchRecord = false,
        }) => {
            /*
            fieldName: name of the field
            itemIndex (optional): leave undefined for header data, index of item for line items
            action (optional): by default update, can also be used to e.g. delete a line item, copy data, ...
            payload (optional): usually containing updated values, can be different for other actions
            itemType (optional): current itemType if multiple item views are available
            forceRefetchRecord (optional): if true, the record will be refetched after the update
            */
            payload = payload || {};

            return update({
                variables: {
                    recordId,
                    action,
                    fieldName,
                    itemIndex,
                    itemType,
                    payload,
                },
                fetchPolicy: 'no-cache',
            })
                .then((res: any) => {
                    const patch = res?.data?.[documentConfiguration?.documentTypeName + 'Update']?.patch;
                    const updatedRecord = res?.data?.[documentConfiguration?.documentTypeName + 'Update']?.record || {};
                    if (patch) {
                        const updatedDocument = applyPatch(
                            record[`${documentConfiguration?.documentTypeName}Unsaved`],
                            adjustPatch(patch)
                        ).newDocument;
                        // we need to do the spread here as we have some areas that rely on if it is a new object or not
                        setRecord({
                            // updatedRecord can still contain information that is not in the patch like if the record is locked or can be completed
                            ...merge(record, updatedRecord),
                            [`${documentConfiguration?.documentTypeName}Unsaved`]: {
                                ...unpackAndMergeDynamicSchemaFields(updatedDocument),
                            },
                        });
                    } else if (updatedRecord) {
                        // fallback if no patch is returned e.g. legacy cases
                        setRecord(updatedRecord);
                    }

                    // This is a quick hack until all use cases work properly with patches,
                    // or we come up with a different solution
                    if (forceRefetchRecord) {
                        return dataRefetch();
                    }
                })
                .catch((error) => {
                    console.error('Error updating record', error);
                    // on error we try to fallback to refetching the whole record
                    // this can happen when there is more data in the backend then we queried originally
                    // from the frontend which would result in json patch to fail
                    return dataRefetch();
                });
        },
        [recordId, record]
    );

    const handleReselect = useCallback(
        ({ fieldName, bbox = undefined, pageIndex = undefined, itemIndex = undefined, itemType = undefined }) => {
            // itemIndex -1 means add new line item

            // Do not trigger a reselect event if the bbox tuples are the same.
            // This happens when the user clicks on the document, so the bbox is just a point,
            // and we don't want to trigger an event for that.
            if (bbox && JSON.stringify(bbox[0]) === JSON.stringify(bbox[1])) {
                return;
            }

            // This way we can mock an actual backend call
            return reselect({
                variables: {
                    recordId,
                    fieldName,
                    bbox,
                    pageIndex,
                    itemIndex,
                    itemType,
                },
                fetchPolicy: 'no-cache',
            })
                .then((res: any) => {
                    const patch = res?.data?.[documentConfiguration?.documentTypeName + 'Reselect']?.patch;
                    const updatedRecord =
                        res?.data?.[documentConfiguration?.documentTypeName + 'Reselect']?.record || {};

                    if (patch) {
                        const updatedDocument = applyPatch(
                            record[`${documentConfiguration?.documentTypeName}Unsaved`],
                            adjustPatch(patch)
                        ).newDocument;
                        // we need to do the spread here as we have some areas that rely on if it is a new object or not
                        setRecord({
                            // updatedRecord can still contain information that is not in the patch like if the record is locked or can be completed
                            ...merge(record, updatedRecord),
                            [`${documentConfiguration?.documentTypeName}Unsaved`]: {
                                ...unpackAndMergeDynamicSchemaFields(updatedDocument),
                            },
                        });
                    } else if (updatedRecord) {
                        // fallback if no patch is returned e.g. legacy cases
                        setRecord(updatedRecord);
                    }
                })
                .catch((error) => {
                    console.error('Error updating record', error);
                    // on error we try to fallback to refetching the whole record
                    // this can happen when there is more data in the backend then we queried originally
                    // from the frontend which would result in json patch to fail
                    return dataRefetch();
                });
        },
        [recordId, record]
    );

    const handleReset = useCallback(() => {
        appInsights?.trackEvent(
            ...discardChangesEvent(user, record, record?.[documentConfiguration?.documentTypeName + 'Unsaved'])
        );
        return handleUpdate({ action: `action:reset`, payload: { stepRunId: stepRun.id }, forceRefetchRecord: true });
    }, [handleUpdate, stepRun.id, history, record]);

    const redirectToNextRecord = useCallback(() => {
        return getNextAssistanceRecord().then(({ data }) => {
            const nextRecordFieldName = camelCase(
                'next_' + documentConfiguration?.documentTypeName + 'ProcessingRecord'
            );
            const isTesting = record?.isTestDocument;
            const isFinished = stepRun?.executionStatus === 'SUCCEEDED';
            const nextAssistanceRecordId = data?.[nextRecordFieldName]?.id;
            const nextPaginationRecordId = pagination?.nextRecordId || pagination?.previousRecordId;

            if (!isFinished && !isTesting && nextAssistanceRecordId != null) {
                // in case the user opened a assistable record and finished or deleted it -> go to next assistable record if there is one
                return navigate(
                    url(
                        documentConfiguration.ASSISTANCE_PATH,
                        { recordId: nextAssistanceRecordId },
                        { keepSearch: true }
                    )
                );
            } else if ((isFinished || isTesting) && nextPaginationRecordId != null) {
                // otherwise check if there is any other record in the pagination and go to that one
                return navigate(
                    url(
                        documentConfiguration.ASSISTANCE_PATH,
                        { recordId: nextPaginationRecordId },
                        { keepSearch: true }
                    )
                );
            } else {
                // in case last item of list was delete go to overview
                return navigate(url(documentConfiguration.OVERVIEW_PATH, undefined, { keepSearch: true }));
            }
        });
    }, [handleUpdate, stepRun.id, history, record, pagination]);

    const handleDiscardConfirm = useCallback(
        (reason) => {
            appInsights?.trackEvent(
                ...removeDocumentEvent(user, record, record?.[documentConfiguration?.documentTypeName + 'Unsaved'])
            );

            return discard({
                variables: {
                    recordId,
                    stepRunId: stepRun.id,
                    reason,
                },
                fetchPolicy: 'no-cache',
            })
                .then(() => {
                    return dataRefetch();
                })
                .then(() => {
                    setDiscardModalVisible(false);
                    return redirectToNextRecord();
                });
        },
        [handleUpdate, stepRun.id, history, record, pagination]
    );
    const handleDiscard = useCallback(() => setDiscardModalVisible(true), []);
    const handleDiscardReject = useCallback(() => setDiscardModalVisible(false), []);

    const handleDelete = useCallback(() => {
        appInsights?.trackEvent(
            ...deleteRecordEvent(user, record, record?.[documentConfiguration.documentTypeName + 'Unsaved'])
        );
        return deleteRecord({
            variables: {
                recordId,
            },
        }).then((res) => {
            return redirectToNextRecord();
        });
    }, [deleteRecord, record, history, pagination]);

    const handleReopenForAssistance = useCallback(
        (reason) => {
            return reopenForAssistance({
                variables: {
                    recordId,
                    stepRunId: stepRun.id,
                    reason: reason,
                },
                fetchPolicy: 'no-cache',
            })
                .then(() => {
                    return dataRefetch();
                })
                .then(() => {
                    return lock();
                });
        },
        [handleUpdate, stepRun.id, history, record]
    );

    const handleFinish = useCallback(
        (event) => {
            appInsights?.trackEvent(
                ...finishAssistanceEvent(user, record, record?.[documentConfiguration?.documentTypeName + 'Unsaved'], {
                    continue: false,
                })
            );

            return handleUpdate({
                action: `action:finish`,
                payload: {
                    stepRunId: stepRun.id,
                },
            })
                .then(() => dataRefetch())
                .then(() =>
                    navigate(
                        url(
                            documentConfiguration.ASSISTANCE_TAB_PATH,
                            { recordId, tab: 'result' },
                            { keepSearch: true }
                        )
                    )
                );
        },
        [handleUpdate, stepRun.id, history, record]
    );

    const handleChangeStatus = useCallback(
        (status, reason = undefined) => {
            return handleUpdate({
                action: `action:change_status`,
                payload: {
                    stepRunId: stepRun.id,
                    status,
                    reason,
                },
            }).then(() => dataRefetch());
        },
        [handleUpdate, stepRun.id, history, record]
    );

    // Get current channel + filters context from store
    const { activeUserFilters, activeChannelId, useNextRecord } = useChannelStore((state) => ({
        activeUserFilters: state.userFilters,
        activeChannelId: state.channelId,
        useNextRecord: state.useNextRecord, // query builder
    }));
    const [getNextAssistanceRecord] = useNextRecord(
        documentConfiguration.GET_NEXT_ASSISTANCE_RECORD,
        activeChannelId,
        activeUserFilters
    );

    const handleFinishAndContinue = useCallback(
        (event) => {
            appInsights?.trackEvent(
                ...finishAssistanceEvent(user, record, record?.[documentConfiguration?.documentTypeName + 'Unsaved'], {
                    continue: true,
                })
            );

            return handleUpdate({
                action: `action:finish`,
                payload: { stepRunId: stepRun.id },
            }).then(() => redirectToNextRecord());
        },
        [handleUpdate, stepRun.id, history, record, pagination]
    );

    const handleRetryStep = useCallback(
        (event) => {
            return retryStep({
                variables: {
                    recordId,
                    stepRunId: stepRun.id,
                },
                fetchPolicy: 'no-cache',
            })
                .then(() => {
                    // go to assistance if step succeeded on retry
                    navigate(
                        url(
                            documentConfiguration.ASSISTANCE_TAB_PATH,
                            { recordId, tab: 'header' },
                            { keepSearch: true }
                        )
                    );
                    // refetch data to show new error message or updated step run
                    void dataRefetch();
                })
                .catch(() => {
                    // refetch data to show new error message
                    void dataRefetch();
                });
        },
        [retryStep, recordId, stepRun.id, history]
    );

    const handleSendToLabeling = useCallback(() => {
        appInsights?.trackEvent(
            ...sendToLabelingEvent(user, record, record?.[documentConfiguration.documentTypeName + 'Unsaved'])
        );
        return sendToLabeling({
            variables: {
                recordId,
            },
            fetchPolicy: 'no-cache',
        }).then(() => {
            return dataRefetch();
        });
    }, [sendToLabeling, record, history, pagination]);

    const updateErrorAlert = useErrorAlert(t('header.alerts.updateError.title'), updateError?.message);
    const reselectErrorAlert = useErrorAlert(t('header.alerts.reselectError.title'), reselectError?.message);
    const retryStepErrorAlert = useErrorAlert(t('header.alerts.retryStepError.title'), retryStepError?.message);

    return useMemo(
        () => ({
            onUpdate: handleUpdate,
            onReselect: handleReselect,
            onReset: handleReset,
            onDiscard: handleDiscard,
            onDiscardConfirm: handleDiscardConfirm,
            onDiscardReject: handleDiscardReject,
            onDelete: handleDelete,
            onFinish: handleFinish,
            onFinishAndContinue: handleFinishAndContinue,
            onRetryStep: handleRetryStep,
            onChangeStatus: handleChangeStatus,
            onReopenForAssistance: handleReopenForAssistance,
            onSendToLabeling: handleSendToLabeling,
            discardModalVisible,
            masterdataBrowserModalVisible,
            masterdataBrowserModalState,
            openMasterdataBrowser,
            closeMasterdataBrowser,
            alert: updateErrorAlert || reselectErrorAlert || retryStepErrorAlert,
            loading:
                updateLoading ||
                reselectLoading ||
                retryStepLoading ||
                reopenForAssistanceLoading ||
                discardLoading ||
                deleteRecordLoading ||
                sendToLabelingLoading,
        }),
        [
            handleUpdate,
            handleReselect,
            handleReset,
            handleDiscard,
            handleDiscardConfirm,
            handleDiscardReject,
            handleDelete,
            handleFinish,
            handleFinishAndContinue,
            handleRetryStep,
            handleChangeStatus,
            handleReopenForAssistance,
            handleSendToLabeling,
            discardModalVisible,
            updateErrorAlert,
            reselectErrorAlert,
            retryStepErrorAlert,
            updateLoading,
            reselectLoading,
            retryStepLoading,
            masterdataBrowserModalVisible,
            pagination,
        ]
    );
};

export default useAssistanceHandlers;
