import * as React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import i18n from 'i18next';

import type { IUser } from '../../interfaces';

import Checkbox from '../../../core/components/Checkbox';
import ProgressButton from '../../../core/containers/ProgressButton';
import Input from '../../../core/components/Input';
import MenuItem from '../../../core/components/MenuItem';
import Select from '../../../core/containers/Select';
import { GET_ROLES, GET_TEAMS } from '../../queries';

import './style.scss';

interface IProps {
    className?: string;
    user?: IUser;
    submitLabel: string;
    onSubmit: (object) => void;
}

const DEFAULT_ROLE = 'Clerk';

const UserForm = (props: IProps) => {
    const { className, onSubmit, submitLabel, user } = props;

    const { t } = useTranslation('users');

    const [firstName, setFirstName] = useState(user?.firstName || '');
    const [lastName, setLastName] = useState(user?.lastName || '');
    const [email, setEmail] = useState(user?.email || '');
    const [language, setLanguage] = useState(user?.language || i18n.language);
    const [roles, setRoles] = useState(user?.groups.map((g) => g.id) || []);
    const [teams, setTeams] = useState(user?.teams.map((t) => t.id) || []);

    useEffect(() => {
        setFirstName(user?.firstName || '');
        setLastName(user?.lastName || '');
        setEmail(user?.email || '');
        setLanguage(user?.language || i18n.language);
        setRoles(user?.groups?.map((g) => g.id) || []);
        setTeams(user?.teams?.map((t) => t.id) || []);
    }, [user]);

    const setRole = (key, checked) => setRoles(checked ? [...roles, key] : roles.filter((k) => k !== key));
    const setTeam = (key, checked) => setTeams(checked ? [...teams, key] : teams.filter((k) => k !== key));
    const handleSubmit = () =>
        onSubmit({
            firstName,
            lastName,
            email,
            roles: roles.filter((r) => r), // prevent "undefined"
            teams: teams.filter((t) => t), // prevent "undefined"
            language,
        });

    const {
        data: rolesData,
        error: rolesError,
        loading: rolesLoading,
    } = useQuery(GET_ROLES, {
        notifyOnNetworkStatusChange: true,
    });
    const {
        data: teamsData,
        error: teamsError,
        loading: teamsLoading,
    } = useQuery(GET_TEAMS, {
        notifyOnNetworkStatusChange: true,
    });

    // fore assignment to clerk group
    useEffect(() => {
        setRole(rolesData?.groups?.find((g) => g.name == DEFAULT_ROLE)?.id, true);
    }, [rolesData]);

    return (
        <div className={classnames('user-form', className)}>
            <div className="user-form__group">
                <label className="user-form__label">{t('form.firstName')}</label>
                <Input
                    className="user-form__input"
                    placeholder="Robyn"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </div>

            <div className="user-form__group">
                <label className="user-form__label">{t('form.lastName')}</label>
                <Input
                    className="user-form__input"
                    placeholder="Roboter"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </div>

            <div className="user-form__group">
                <label className="user-form__label">{t('form.email')}</label>
                <Input
                    disabled={!!user?.id}
                    className="user-form__input"
                    placeholder="robyn@workist.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>

            <div className="user-form__group">
                <label className="user-form__label">{t('form.language')}</label>
                <Select className="user-profile__input" value={language} onChange={(value) => setLanguage(value)}>
                    <MenuItem value="de" label="Deutsch" />
                    <MenuItem value="en" label="English" />
                    <MenuItem value="pl" label="Polski" />
                </Select>
            </div>

            <div className="user-form__group">
                <label className="user-form__label">{t('form.roles')}</label>
                {(rolesData?.groups || []).map((role) => (
                    <Checkbox
                        key={role.id}
                        disabled={role.name == DEFAULT_ROLE}
                        checked={roles.includes(role.id) || role.name == DEFAULT_ROLE}
                        onChange={(e) => setRole(role.id, e.target.checked)}
                        label={role.name}
                    />
                ))}
            </div>

            <div className="user-form__group">
                <label className="user-form__label">{t('form.teams')}</label>
                {(teamsData?.teams || []).map((team) => (
                    <Checkbox
                        key={team.id}
                        disabled={team.isDefault}
                        checked={teams.includes(team.id) || team.isDefault}
                        onChange={(e) => setTeam(team.id, e.target.checked)}
                        label={team.name}
                    />
                ))}
            </div>

            <div className="user-form__group">
                <ProgressButton label={submitLabel} onClick={handleSubmit} />
            </div>
        </div>
    );
};

export default UserForm;
