import { useTranslation } from 'react-i18next';
import moment from 'moment';

import './style.scss';

export const EXTERNAL_STATUS_FILTER_CHOICES = {
    SUCCESSFULLY_ENTERED_API_STATUS: 'Erfolgreich_erfasst',
    ENTER_MANUALLY_API_STATUS: 'Manuell_erfassen',
    ENTERED_MANUALLY_API_STATUS: 'Manuell_erfasst',
    INCOMPLETE_API_STATUS: 'Unvollständig',
    EMPTY_API_STATUS: '__empty__',
};

export const formatSender = (record) => {
    const { t } = useTranslation('assistance');

    if (record?.sender) {
        return record?.sender;
    } else if (record?.createdAt) {
        const startedAt = record?.createdAt;
        const title = startedAt && moment(startedAt).format('dddd, Do MMMM YYYY, HH:mm');
        return title ? `${t('overview.recordRow.titleReceivedAt')} ${title}` : '';
    }
};

export const formatSubject = (record) => {
    return record?.subject || null;
};

export const formatTime = (time) => {
    const parsed = moment(time);
    const isToday = parsed.isSame(new Date(), 'day');

    if (isToday) {
        return parsed.format('LT');
    } else {
        return parsed.format('lll');
    }
};
