export const unpackAndMergeDynamicSchemaFields = <T>(
    objectWithDynamicSchemaFields: T,
    dynamicSchemaFieldName = 'dynamicSchema',
    dynamicSchemaSerializedFieldName = 'dynamicSchemaSerialized'
): T => {
    function _traverseObject(obj) {
        for (const key in obj) {
            // Determine if there is a dynamic schema field at the current level of the object tree
            // (either a polymorphic type or embedded as a serialized string)
            let dynamicSchema;
            if (obj.hasOwnProperty(dynamicSchemaFieldName)) {
                // polymorphic type
                dynamicSchema = obj[dynamicSchemaFieldName];
            }
            if (obj.hasOwnProperty(dynamicSchemaSerializedFieldName)) {
                // embedded string
                let dynamicSchemaString = obj[dynamicSchemaSerializedFieldName];
                dynamicSchema ??= JSON.parse(dynamicSchemaString);
            }

            // Recurse all the way to object leaf nodes
            let value = obj[key];
            if (value != undefined) {
                if (value && typeof value === 'object') {
                    _traverseObject(value);
                }
            }

            // Make sure to traverse nested dynamic schemas
            if (dynamicSchema && typeof dynamicSchema === 'object') {
                _traverseObject(dynamicSchema);
            }

            // Merge dynamic schema into the current level of the object tree as we unwind the recursion
            if (dynamicSchema != null) {
                for (const dynamicSchemaKey in dynamicSchema) {
                    // Skip protected fields
                    if (dynamicSchemaKey.startsWith('__')) {
                        continue;
                    }
                    // Copy over the key/value pair from inside the dynamic schema
                    obj[dynamicSchemaKey] = dynamicSchema[dynamicSchemaKey];
                }
                // Delete the dynamic schema field(s) since we no longer need them
                delete obj[dynamicSchemaFieldName];
                delete obj[dynamicSchemaSerializedFieldName];
            }
        }
    }

    _traverseObject(objectWithDynamicSchemaFields);
    return objectWithDynamicSchemaFields; // original object mutated in-place
};
