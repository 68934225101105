import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { IconButton } from '../IconButton';
import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';

import './style.scss';

export interface IBaseModalProps {
    className?: string;
    children?: any;
    visible?: boolean;
    // the modal is considered dismissible if onClose != null (note: visibility is still controlled externally)
    onClose?: () => void;
    title?: React.ReactNode | string;
    buttons?: any;
    noHeader?: boolean;
    containerRef?: any;
    allowMaximize?: boolean;
}

const Modal = (props: IBaseModalProps) => {
    const {
        className,
        visible = true,
        allowMaximize = false,
        buttons,
        title,
        noHeader,
        onClose,
        children,
        containerRef,
    } = props;

    const defaultContainerRef = useRef(document.getElementById('modal-root'));
    const modalContainerRef = containerRef || defaultContainerRef;

    const [open, setOpen] = useState(visible);
    const [isMaximized, setIsMaximized] = useState(false);
    useEffect(() => setOpen(visible), [visible]);

    const handleOpenChange = (open) => {
        setOpen(open);
        onClose?.();
    };

    const handleWrapperClick = (event) => {
        // if not clicking on the modal itself, close the modal
        if (!event.target.closest('.dialog__modal')) {
            handleOpenChange(false);
        }
    };

    if (!modalContainerRef?.current) return null;

    return (
        <Dialog.Root open={open} onOpenChange={handleOpenChange}>
            <Dialog.Portal container={modalContainerRef?.current}>
                <Dialog.Overlay className="dialog__overlay" />
                <Dialog.Content
                    onOpenAutoFocus={(event) => event.preventDefault()}
                    className={classNames(
                        'dialog__wrapper dark-mode-ready',
                        isMaximized && 'dialog__wrapper--fullscreen'
                    )}
                    onClick={handleWrapperClick}
                >
                    <div className={classNames('dialog__modal', className)}>
                        {!noHeader && (
                            <div className={classNames('dialog__header', !title && 'dialog__header--no-title')}>
                                {title && <Dialog.Title className="dialog__header__title">{title}</Dialog.Title>}
                                <div className="dialog__header__buttons">
                                    {allowMaximize && (
                                        <IconButton
                                            tabIndex={-1} // unfocusable (will autofocus the first child input instead)
                                            icon={isMaximized ? 'minimizeWindow' : 'maximizeWindow'}
                                            onClick={() => setIsMaximized((value) => !value)}
                                        />
                                    )}
                                    <Dialog.Close asChild>
                                        <IconButton
                                            tabIndex={-1} // unfocusable (will autofocus the first child input instead)
                                            icon="close"
                                            onClick={() => handleOpenChange(false)}
                                        />
                                    </Dialog.Close>
                                </div>
                            </div>
                        )}
                        <div className={classNames('dialog__content', noHeader && 'dialog__content--no-header')}>
                            <div className="dialog__content__children">{children}</div>
                            {buttons && <div className="dialog__buttons">{buttons}</div>}
                        </div>
                    </div>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default Modal;
